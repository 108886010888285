import useAxios from "@/composables/useAxios"
import type {
    ApiUpcomingInvoiceData,
    BillingModelId,
    BillingModelType,
} from "types/api"
import type { Ref } from "vue"
import { useQuery } from "@tanstack/vue-query"
import { Invoice } from "@/models/Invoice"
import config from "@/config"
import { QueryKey } from "../QueryKey"

export function useUpcomingInvoiceQuery(
    modelType: Ref<BillingModelType>,
    modelId: Ref<BillingModelId | undefined>
) {
    return useQuery({
        queryKey: [
            QueryKey.base("invoices"),
            QueryKey.base("upcoming-invoice"),
            modelType,
            modelId,
        ],

        queryFn: () =>
            useAxios()
                .axios.get<ApiUpcomingInvoiceData | Record<any, never>>(
                    `/api/${modelType.value}/${modelId.value}/billing/invoices/upcoming`
                )
                .then((response) => response.data),

        select: (data) => {
            if (Object.keys(data).length) {
                return new Invoice(data as ApiUpcomingInvoiceData)
            }
            return null
        },

        enabled: computed(() => !!modelId.value),

        staleTime: config.staleTime.long,
    })
}
