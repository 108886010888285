import { useMutation, useQueryClient } from "@tanstack/vue-query"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import type { ApiCreateInvitationPayload } from "types/api"
import { orgInvitationBaseKey } from "./useOrgInvitationsQuery"

export function useOrgInvitationCreate() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            orgId,
            payload,
        }: {
            orgId: Organization["id"]
            payload: ApiCreateInvitationPayload
        }) =>
            useAxios().axios.post(
                `/api/organizations/${orgId}/invites`,
                payload
            ),
        onSettled() {
            queryClient.invalidateQueries({ queryKey: [orgInvitationBaseKey] })
        },
    })
}
