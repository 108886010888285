import useAxios from "@/composables/useAxios"
import type User from "@/models/User"
import type UserSignature from "@/models/UserSignature"
import type { AxiosError } from "axios"
import type { ApiError } from "types/api"
import {
    useMutation,
    useQueryClient,
    type UseMutationOptions,
} from "@tanstack/vue-query"
import { QueryKey } from "../QueryKey"

export function useUserInitialDelete(
    config?: UseMutationOptions<
        unknown,
        AxiosError<ApiError>,
        { userId: User["id"] | "me"; userSigId: UserSignature["id"] },
        unknown
    >
) {
    const { axios } = useAxios()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ userId, userSigId }) =>
            axios.delete(`/api/users/${userId}/initials/${userSigId}`),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: QueryKey.baseKey("user-initials"),
            })
        },

        ...config,
    })
}
