import type { Ref } from "vue"
import { useQuery } from "@tanstack/vue-query"
import type User from "@/models/User"
import PromoCode from "@/models/PromoCode"
import useAxios from "@/composables/useAxios"
import type { ApiPromoCodeData } from "types/api"
import type Organization from "@/models/Organization"
import type { ModelSubscription } from "@/models/ModelSubscription"

export const promoCodeBaseKey = "promoCode"

export function usePromoCodeFindQuery(
    modelType: Ref<ModelSubscription["modelType"]>,
    modelId: Ref<User["id"] | Organization["id"] | undefined>,
    promoCode: Ref<string | null>
) {
    return useQuery({
        queryKey: [promoCodeBaseKey, promoCode, modelType, modelId],
        queryFn: () =>
            useAxios()
                .axios.get<ApiPromoCodeData>(
                    `/api/${modelType.value}/${modelId.value}/billing/subscription/promo-code/${promoCode.value}`
                )
                .then((resp) => resp.data),
        select: (data) => new PromoCode(data),
        enabled: computed(() => !!modelId.value && !!promoCode.value),
        retry: false,
    })
}
