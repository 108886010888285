import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import type { ApiPaginatedGETResponse } from "types/api"
import { reactive } from "vue"
import { useQuery } from "@tanstack/vue-query"

export const organizationsBaseKey = "organizations" as const

export default function useOrganizationsQuery() {
    const { axios } = useAxios()

    const query = useQuery({
        queryKey: [organizationsBaseKey],
        queryFn: async () => {
            return await axios
                // fixme Not returning Organization, but rather api data
                .get<ApiPaginatedGETResponse<Organization>>(
                    `/api/organizations`,
                    {
                        params: { append: "logo_base64" },
                    }
                )
                .then((resp) => resp.data)
                .then((data) => data.data)
        },
    })

    // fixme
    return reactive(query)
}
