import type { Ref } from "vue"
import { useQuery } from "@tanstack/vue-query"
import type User from "@/models/User"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import type { ModelSubscription } from "@/models/ModelSubscription"
import { QueryKey } from "../QueryKey"
import config from "@/config"

export function useAccessCodeQuery(
    modelType: Ref<ModelSubscription["modelType"]>,
    modelId: Ref<User["id"] | Organization["id"] | undefined>
) {
    return useQuery({
        queryKey: [QueryKey.base("access-code"), modelType, modelId],
        queryFn: () =>
            useAxios()
                .axios.get(
                    `/api/${modelType.value}/${modelId.value}/billing/access-code`
                )
                .then((resp) => {
                    if (resp.status === 204) return false
                    if (resp.status === 200) return true

                    throw new Error("GF: Invalid access code response")
                }),
        enabled: computed(() => !!modelId.value),
        retry: false,
        staleTime: config.staleTime.long,
    })
}
