import useAxios from "@/composables/useAxios"
import UserSignature from "@/models/UserSignature"
import { useQuery } from "@tanstack/vue-query"
import type { ApiPaginatedGETResponse, ApiUserSignatureData } from "types/api"
import type User from "@/models/User"
import { QueryKey } from "../QueryKey"
import config from "@/config"
import type { Ref } from "vue"
import type Signee from "@/models/Signee"

/**
 *
 * @param userId
 * @param signeeId Optional signee id, used to help invalidate the query when the signee changes.
 * Specifically in the case of in-person-signing.
 */
export function useUserSignatureQuery(
    userId: Ref<User["id"] | null | "me">,
    signeeId?: Ref<Signee["id"] | null>
) {
    const { axios } = useAxios()

    return useQuery({
        queryKey: [QueryKey.base("user-signatures"), userId, signeeId],
        queryFn: () =>
            axios
                .get<ApiPaginatedGETResponse<ApiUserSignatureData>>(
                    `/api/users/${userId.value}/signatures`,
                    { params: { sort: "-created_at" } }
                )
                .then((resp) => resp.data.data),
        select: (data) => data.map((data) => new UserSignature(data)),
        enabled: computed(() => !!userId.value),
        staleTime: config.staleTime.long,
    })
}
