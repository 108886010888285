<template>
    <svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M149.4 0.969971H50.72C22.7412 0.969971 0.0599899 23.6512 0.0599899 51.63V150.31C0.0599899 178.289 22.7412 200.97 50.72 200.97H149.4C177.379 200.97 200.06 178.289 200.06 150.31V51.63C200.06 23.6512 177.379 0.969971 149.4 0.969971V0.969971Z"
            fill="url(#header-llb-horizontal-over-black-paint0_linear)"
        ></path>
        <path
            d="M66.695 148.64H44.395C43.1873 148.637 42.03 148.156 41.177 147.301C40.324 146.446 39.845 145.288 39.845 144.08V109.74C39.8423 108.8 39.4672 107.9 38.8017 107.236C38.1362 106.573 37.2348 106.2 36.295 106.2C35.3569 106.203 34.4581 106.576 33.7947 107.24C33.1314 107.903 32.7576 108.802 32.755 109.74V144.08C32.7576 147.166 33.9848 150.125 36.1672 152.308C38.3495 154.49 41.3087 155.717 44.395 155.72H66.695V148.64Z"
            fill="white"
        ></path>
        <path
            d="M163.795 89.27H159.795V96.36H160.305V144.08C160.302 145.289 159.821 146.447 158.966 147.302C158.112 148.156 156.954 148.637 155.745 148.64H133.365V155.72H155.745C158.831 155.717 161.79 154.49 163.973 152.308C166.155 150.125 167.382 147.166 167.385 144.08V92.81C167.385 92.3409 167.292 91.8765 167.111 91.4437C166.93 91.0109 166.665 90.6184 166.331 90.289C165.997 89.9596 165.6 89.6999 165.165 89.525C164.73 89.3501 164.264 89.2634 163.795 89.27V89.27Z"
            fill="white"
        ></path>
        <path
            d="M99.795 121.25C97.1383 121.254 94.5068 120.735 92.0513 119.72C89.5958 118.706 87.3644 117.218 85.485 115.34C81.6953 111.542 79.5669 106.395 79.5669 101.03C79.5669 95.6645 81.6953 90.5182 85.485 86.72C89.2832 82.9303 94.4295 80.8019 99.795 80.8019C105.16 80.8019 110.307 82.9303 114.105 86.72C114.764 87.3849 115.135 88.2835 115.135 89.22C115.135 90.1565 114.764 91.0551 114.105 91.72C113.44 92.3795 112.541 92.7496 111.605 92.7496C110.668 92.7496 109.77 92.3795 109.105 91.72C106.637 89.2554 103.293 87.8711 99.805 87.8711C96.3174 87.8711 92.9725 89.2554 90.505 91.72C88.0404 94.1875 86.656 97.5324 86.656 101.02C86.656 104.508 88.0404 107.852 90.505 110.32C92.9751 112.78 96.3191 114.161 99.805 114.161C103.291 114.161 106.635 112.78 109.105 110.32C109.433 109.991 109.823 109.73 110.252 109.552C110.681 109.374 111.14 109.282 111.605 109.282C112.069 109.282 112.529 109.374 112.958 109.552C113.387 109.73 113.777 109.991 114.105 110.32C114.764 110.985 115.135 111.883 115.135 112.82C115.135 113.757 114.764 114.655 114.105 115.32C112.227 117.201 109.997 118.694 107.541 119.711C105.085 120.729 102.453 121.252 99.795 121.25Z"
            fill="white"
        ></path>
        <path
            d="M100.035 164.79C99.4599 164.789 98.8937 164.648 98.3851 164.38C97.8764 164.112 97.4406 163.724 97.115 163.25L69.485 122.97C64.7902 116.133 62.5288 107.916 63.0638 99.6396C63.5987 91.3629 66.8991 83.506 72.435 77.33C75.9092 73.4466 80.1626 70.3389 84.9181 68.2093C89.6736 66.0797 94.8244 64.9759 100.035 64.97C105.243 64.9679 110.392 66.0651 115.146 68.1898C119.901 70.3145 124.153 73.4189 127.625 77.3C133.168 83.4702 136.478 91.3241 137.022 99.6009C137.566 107.878 135.313 116.097 130.625 122.94L102.995 163.25C102.666 163.73 102.223 164.121 101.707 164.389C101.191 164.658 100.617 164.796 100.035 164.79V164.79ZM100.035 72.05C95.8209 72.0557 91.6554 72.9492 87.8096 74.6722C83.9639 76.3951 80.5243 78.909 77.715 82.05C73.2296 87.0421 70.5548 93.3986 70.1213 100.096C69.6877 106.793 71.5207 113.441 75.325 118.97L100.035 154.97L124.735 118.97C128.54 113.444 130.374 106.798 129.942 100.102C129.511 93.407 126.838 87.0517 122.355 82.06C119.547 78.9172 116.107 76.4014 112.262 74.6767C108.416 72.952 104.25 72.0569 100.035 72.05Z"
            fill="white"
        ></path>
        <path
            d="M155.695 55.07H133.915C133.039 55.0679 132.182 54.8142 131.447 54.3391C130.711 53.864 130.127 53.1874 129.765 52.39L125.975 43.98C125.05 41.9406 123.558 40.2104 121.677 38.9958C119.795 37.7812 117.604 37.1335 115.365 37.13H84.555C82.3493 37.1352 80.1901 37.7642 78.3268 38.9445C76.4635 40.1247 74.9721 41.808 74.025 43.8L69.945 52.46C69.5748 53.2397 68.9914 53.8986 68.2623 54.3605C67.5331 54.8224 66.6881 55.0684 65.825 55.07H44.375C41.2895 55.0753 38.3319 56.3033 36.1501 58.4851C33.9683 60.6669 32.7403 63.6245 32.735 66.71V92.81C32.7337 93.2757 32.8243 93.7371 33.0016 94.1678C33.1789 94.5984 33.4394 94.9899 33.7683 95.3196C34.0971 95.6494 34.4878 95.911 34.918 96.0896C35.3481 96.2681 35.8093 96.36 36.275 96.36H40.815V89.27H39.815V66.71C39.8176 65.5023 40.2992 64.3451 41.1541 63.4921C42.009 62.6391 43.1673 62.16 44.375 62.16H65.825C68.0305 62.1545 70.1894 61.524 72.0513 60.3417C73.9132 59.1595 75.4019 57.4738 76.345 55.48L80.435 46.83C80.8028 46.0477 81.3853 45.386 82.1148 44.9221C82.8443 44.4582 83.6905 44.2113 84.555 44.21H115.365C116.24 44.2145 117.096 44.4692 117.831 44.9441C118.567 45.4189 119.151 46.0941 119.515 46.89L123.305 55.31C124.23 57.3494 125.722 59.0796 127.603 60.2943C129.485 61.5089 131.676 62.1565 133.915 62.16H155.695C156.903 62.16 158.061 62.6391 158.916 63.4921C159.771 64.3451 160.252 65.5023 160.255 66.71V77.83C160.254 78.2957 160.344 78.7571 160.522 79.1878C160.699 79.6184 160.959 80.0098 161.288 80.3396C161.617 80.6694 162.008 80.9311 162.438 81.1096C162.868 81.2881 163.329 81.38 163.795 81.38C164.261 81.38 164.722 81.2881 165.152 81.1096C165.582 80.9311 165.973 80.6694 166.302 80.3396C166.631 80.0098 166.891 79.6184 167.068 79.1878C167.246 78.7571 167.336 78.2957 167.335 77.83V66.71C167.33 63.6245 166.102 60.6669 163.92 58.4851C161.738 56.3033 158.78 55.0753 155.695 55.07V55.07Z"
            fill="white"
        ></path>
        <path
            d="M69.265 92.81C69.265 91.8712 68.892 90.9708 68.2281 90.3069C67.5643 89.643 66.6639 89.27 65.725 89.27H39.845V96.36H65.725C66.1907 96.36 66.6519 96.2681 67.082 96.0896C67.5121 95.9111 67.9028 95.6495 68.2317 95.3197C68.5605 94.9899 68.8211 94.5985 68.9984 94.1678C69.1757 93.7372 69.2663 93.2757 69.265 92.81V92.81Z"
            fill="url(#header-llb-horizontal-over-black-paint1_linear)"
        ></path>
        <path
            d="M130.533 92.8097C130.533 91.8708 130.906 90.9704 131.57 90.3065C132.234 89.6426 133.135 89.2697 134.073 89.2697H159.953V96.3597H134.073C133.608 96.3597 133.147 96.2678 132.716 96.0892C132.286 95.9107 131.896 95.6491 131.567 95.3193C131.238 94.9895 130.977 94.5981 130.8 94.1674C130.623 93.7368 130.532 93.2754 130.533 92.8097Z"
            fill="url(#header-llb-horizontal-over-black-paint2_linear)"
        ></path>
        <path
            d="M104.2 152.17C104.2 151.231 104.573 150.331 105.237 149.667C105.901 149.003 106.801 148.63 107.74 148.63H133.63V155.71H107.71C106.777 155.699 105.886 155.322 105.229 154.66C104.572 153.997 104.203 153.103 104.2 152.17V152.17Z"
            fill="url(#header-llb-horizontal-over-black-paint3_linear)"
        ></path>
        <path
            d="M95.8 152.19C95.8 151.251 95.427 150.351 94.7631 149.687C94.0993 149.023 93.1988 148.65 92.26 148.65H66.37V155.73H92.29C93.2228 155.719 94.114 155.342 94.7709 154.68C95.4277 154.017 95.7974 153.123 95.8 152.19Z"
            fill="url(#header-llb-horizontal-over-black-paint4_linear)"
        ></path>

        <defs>
            <linearGradient
                id="header-llb-horizontal-over-black-paint0_linear"
                x1="38"
                y1="22.41"
                x2="117"
                y2="122.41"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#3A85DB"></stop>
                <stop offset="1" stop-color="#0967D2"></stop>
            </linearGradient>
            <linearGradient
                id="header-llb-horizontal-over-black-paint1_linear"
                x1="39.815"
                y1="92.81"
                x2="69.235"
                y2="92.81"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.1" stop-color="white"></stop>
                <stop offset="0.85" stop-color="white" stop-opacity="0"></stop>
            </linearGradient>
            <linearGradient
                id="header-llb-horizontal-over-black-paint2_linear"
                x1="159.983"
                y1="92.8097"
                x2="130.563"
                y2="92.8097"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.1" stop-color="white"></stop>
                <stop offset="0.85" stop-color="white" stop-opacity="0"></stop>
            </linearGradient>
            <linearGradient
                id="header-llb-horizontal-over-black-paint3_linear"
                x1="133.365"
                y1="152.18"
                x2="103.935"
                y2="152.18"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white"></stop>
                <stop offset="0.85" stop-color="white" stop-opacity="0"></stop>
            </linearGradient>
            <linearGradient
                id="header-llb-horizontal-over-black-paint4_linear"
                x1="66.695"
                y1="152.18"
                x2="96.125"
                y2="152.18"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white"></stop>
                <stop offset="0.85" stop-color="white" stop-opacity="0"></stop>
            </linearGradient>
        </defs>
    </svg>
</template>
<script lang="ts" setup></script>
