import useAxios from "@/composables/useAxios"
import { userTokensBaseKey } from "./useTokensQuery"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import type { UserToken } from "@/models/UserToken"
import type User from "@/models/User"

export function useRevokeToken() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({
            tokenId,
            userId,
        }: {
            tokenId: UserToken["id"]
            userId: User["id"]
        }) => useAxios().axios.delete(`/api/users/${userId}/tokens/${tokenId}`),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [userTokensBaseKey],
            })
        },
    })
}
