import useAxios from "@/composables/useAxios"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import useNotification from "@/composables/useNotification"
import type { BillingModelType, BillingModelId } from "types/api"
import type { PaymentMethod as StripePaymentMethod } from "@stripe/stripe-js"
import { QueryKey } from "../QueryKey"

interface Payload {
    payment_method: string | StripePaymentMethod
}

export function usePaymentMethodCreate() {
    const queryClient = useQueryClient()
    const notification = useNotification()

    return useMutation({
        mutationFn: (payload: {
            modelType: BillingModelType
            modelId: BillingModelId
            payload: Payload
        }) =>
            useAxios().axios.post(
                `/api/${payload.modelType}/${payload.modelId}/billing/payment-methods`,
                payload.payload
            ),
        onSuccess() {
            notification.open({
                type: "success",
                title: "Payment method added",
                message: "Your payment method has been successfully added.",
            })
            queryClient.invalidateQueries({
                queryKey: QueryKey.baseKey("payment-methods"),
            })
        },
    })
}
