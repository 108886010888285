import Stub from "@/models/Stub"
import type PDF from "@/models/PDF"
import type User from "@/models/User"
import { QueryKey } from "../QueryKey"
import type { ComputedRef, Ref } from "vue"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import { useInfiniteQuery } from "@tanstack/vue-query"
import type { ApiPaginatedGETResponse, ApiStubData } from "types/api"

export type StubQueryKey = ComputedRef<{
    modelType: "users" | "organizations" | "pdf"
    modelId: User["id"] | Organization["id"] | PDF["id"] | undefined | null
}>

export function useStubsInfiniteQuery(
    key: StubQueryKey,
    config: { enabled: Ref<boolean> } = { enabled: ref(true) }
) {
    const { axios } = useAxios()

    const total = ref<number | undefined>(undefined)

    const query = useInfiniteQuery({
        initialPageParam: 1,
        queryKey: [QueryKey.base("stubs"), key],
        queryFn: ({ pageParam = 1 }) => {
            let uri

            if (key.value.modelType === "pdf") {
                uri = `/api/documents/pdfs/${key.value.modelId}/stubs`
            } else {
                uri = `/api/${key.value.modelType}/${key.value.modelId}/stubs`
            }

            return axios
                .get<ApiPaginatedGETResponse<ApiStubData>>(uri, {
                    params: {
                        "page[number]": pageParam,
                        "page[size]": 100,
                        "sort[createdAt]": "key",
                        include: [],
                    },
                })
                .then((resp) => {
                    total.value = resp.data.total
                    return resp.data
                })
        },
        enabled: computed(() => config?.enabled.value ?? true),

        select({ pageParams, pages }) {
            total.value = pages[0]?.total
            const transformedPages = pages.map((page) => {
                return page.data.map((stubData) => new Stub(stubData))
            })
            return { pages: transformedPages, pageParams }
        },

        getNextPageParam: (lastPage, _pages) =>
            lastPage.next_page_url ? lastPage.current_page + 1 : undefined,

        // staleTime: configFile.staleTime.long,
    })

    const stubs = computed(() => query.data.value?.pages.flat())

    return { ...query, stubs, total }
}

export function useAllStubs(
    key: StubQueryKey,
    config?: { enabled: Ref<boolean> }
) {
    const isLoadingLocal = ref(true)
    const results = ref<Stub[]>([])

    const { stubs, hasNextPage, fetchNextPage, isRefetching, total } =
        useStubsInfiniteQuery(key, config)

    watch(
        [hasNextPage, computed(() => stubs.value?.length), isRefetching],
        () => {
            if (hasNextPage?.value && !isRefetching?.value) {
                fetchNextPage()
            }
            if (!hasNextPage?.value && !isRefetching?.value) {
                isLoadingLocal.value = false
                results.value = stubs.value ?? []
            }
        }
    )

    return {
        data: results,
        isLoading: isLoadingLocal,
        isRefetching,
        total,
    }
}

export function useAllStubsGroupedByService(
    key: StubQueryKey,
    config?: { enabled: Ref<boolean> }
) {
    const isLoadingLocal = ref(true)
    const results = ref<Stub[]>([])
    const resultsSorted = ref<[string | null, Stub[]][]>([])

    const { stubs, hasNextPage, fetchNextPage, isRefetching, total } =
        useStubsInfiniteQuery(key, config)

    watch(
        [hasNextPage, computed(() => stubs.value?.length), isRefetching],
        () => {
            if (hasNextPage?.value && !isRefetching?.value) {
                fetchNextPage()
            }
            if (!hasNextPage?.value && !isRefetching?.value) {
                isLoadingLocal.value = false
                results.value = stubs.value ?? []
            }
        }
    )

    watch(
        computed(() => results.value),
        () => {
            const grouped = results.value.reduce<[string | null, Stub[]][]>(
                (acc, stub) => {
                    const service = stub.service_name
                    const existing = acc.find(([name]) => name === service)

                    if (existing) {
                        existing[1].push(stub)
                    } else {
                        acc.push([service, [stub]])
                    }

                    return acc
                },
                []
            )

            resultsSorted.value = grouped.sort(([a], [b]) => {
                if (a === null) return -1
                if (b === null) return 1
                return a.localeCompare(b)
            })
        }
    )

    return {
        data: resultsSorted,
        isLoading: isLoadingLocal,
        isRefetching,
        total,
    }
}
