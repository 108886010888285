<template>
    <div>
        <SectionLayout
            title="API Tokens"
            description="API tokens allow programmatic access to your Galaxy Forms account. They can be used to integrate with third-party services or your own custom application."
            class="mt-8"
            is-table
        >
            <GFTable
                :data="data ?? []"
                :cols="[
                    ['name', 'Name'],
                    ['last_used_at', 'Last used'],
                    ['created_at', 'Created'],
                    'actions',
                ]"
                :loading="isLoading"
            >
                <template #last_used_at="{ row }">
                    {{ row.lastUsedAt ? row.lastUsedAt : "Never" }}
                </template>
                <template #created_at="{ row }">
                    {{ row.createdAt }}
                </template>
                <template #actions="{ row }">
                    <GFButtonText
                        size="sm"
                        type="danger"
                        @click="deleteToken = row"
                    >
                        Revoke
                    </GFButtonText>
                </template>
            </GFTable>
            <template #actions>
                <GFButton @click="showCreateNewToken = true">
                    New token
                </GFButton>
            </template>
        </SectionLayout>

        <GFModalSimple
            v-model:show="showCreateNewToken"
            :size="displayingToken ? 'large' : 'normal'"
            @closed="
                () => {
                    tokenName = null
                    displayingToken = null
                }
            "
        >
            <div class="mx-auto flex w-full flex-col space-y-3">
                <GFInput
                    ref="input"
                    v-model="tokenName"
                    :disabled="!!displayingToken"
                    label="Token name or service this is for"
                    placeholder="ex: API, Testing, Custom Integration"
                    class="w-full"
                    autofocus
                    @keyup.enter="onCreateNewToken"
                ></GFInput>

                <div v-if="displayingToken">
                    <div
                        class="flex items-center justify-between overflow-auto rounded py-2"
                    >
                        <div
                            class="flex h-10 flex-grow items-center justify-center rounded border bg-gray-200 text-center text-xs font-semibold text-gray-800 md:text-sm"
                        >
                            {{ displayingToken.token }}
                        </div>
                        <GFButtonIcon
                            :icon="copyIcon"
                            class="ml-2 mr-1 hidden h-8 w-8 cursor-pointer md:flex"
                            @click="
                                () =>
                                    displayingToken?.token &&
                                    copy(displayingToken?.token)
                            "
                        ></GFButtonIcon>
                    </div>
                    <div class="flex text-sm font-medium">
                        <GFIcon
                            class="mr-2 h-10 w-10 text-blue-500"
                            rem-size="1.5"
                            icon="exclamation-circle"
                        ></GFIcon>
                        Please store your token in a secure location. It will
                        not be accessible once you close this window.
                    </div>
                </div>
            </div>
            <template #buttons>
                <GFButton
                    v-if="!displayingToken"
                    :disabled="!tokenName"
                    class="ml-2"
                    :loading="creating"
                    @click="onCreateNewToken"
                >
                    Create
                </GFButton>
                <GFButtonSimple
                    v-if="!displayingToken"
                    @click="showCreateNewToken = false"
                >
                    Cancel
                </GFButtonSimple>

                <GFButtonSimple
                    v-if="displayingToken"
                    @click="showCreateNewToken = false"
                >
                    Close
                </GFButtonSimple>
            </template>
        </GFModalSimple>

        <GFModalSimple v-model:show="showConfirmDeleteToken">
            <div class="mx-auto flex flex-col space-y-3 md:w-96">
                <span>
                    Are you sure you want to revoke the
                    <span class="font-bold"> {{ deleteToken?.name }} </span>
                    token?
                </span>
            </div>
            <template #buttons>
                <GFButton
                    class="ml-3"
                    :loading="revoking"
                    @click="() => deleteToken && revoke(deleteToken?.id)"
                >
                    Revoke
                </GFButton>
                <GFButtonSimple
                    @click="
                        () => {
                            showConfirmDeleteToken = false
                            deleteToken = null
                        }
                    "
                >
                    Cancel
                </GFButtonSimple>
            </template>
        </GFModalSimple>
    </div>
</template>
<script lang="ts" setup>
import GFIcon from "../base/GFIcon.vue"
import GFTable from "../base/GFTable.vue"
import GFInput from "../base/GFInput.vue"
import GFButton from "../base/GFButton.vue"
import { useUserStore } from "@/stores/user"
import type { ApiGlobalId } from "types/api"
import { UserToken } from "@/models/UserToken"
import GFButtonText from "../base/GFButtonText.vue"
import GFButtonIcon from "../base/GFButtonIcon.vue"
import GFModalSimple from "../base/GFModalSimple.vue"
import SectionLayout from "../layout/SectionLayout.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import { useCopyText } from "@/composables/useCopyText"
import { useCreateToken } from "@/queries/user-tokens/useCreateToken"
import { useRevokeToken } from "@/queries/user-tokens/useRevokeToken"
import { useTokensQuery } from "@/queries/user-tokens/useTokensQuery"

const { copyIcon, copy } = useCopyText()
const userStore = useUserStore()

const showCreateNewToken = ref(false)
const showConfirmDeleteToken = ref(false)
const tokenName = ref<string | null>(null)
const deleteToken = ref<UserToken | null>(null)
const displayingToken = ref<null | UserToken>(null)

const { mutate: createToken, isPending: creating } = useCreateToken()
const { mutate: revokeToken, isPending: revoking } = useRevokeToken()
const { data, isLoading } = useTokensQuery(computed(() => userStore.user?.id))

watch(deleteToken, (id) => {
    if (id) showConfirmDeleteToken.value = true
})

function onCreateNewToken() {
    if (!tokenName.value) return
    if (!userStore.user) return

    createToken(
        {
            payload: { name: tokenName.value },
            userId: userStore.user.id,
        },
        {
            onSuccess: (data) => {
                const token = new UserToken(data.data)
                displayingToken.value = token
            },
        }
    )
}

function revoke(tokenId: ApiGlobalId) {
    if (!userStore.user) return

    revokeToken(
        {
            tokenId,
            userId: userStore.user.id,
        },
        {
            onSuccess() {
                showConfirmDeleteToken.value = false
                deleteToken.value = null
            },
        }
    )
}
</script>
