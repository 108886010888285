import useAxios from "@/composables/useAxios"
import { userTokensBaseKey } from "./useTokensQuery"
import type { ApiCreateTokenPayload } from "types/api"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import type User from "@/models/User"

export function useCreateToken() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({
            payload,
            userId,
        }: {
            payload: ApiCreateTokenPayload
            userId: User["id"]
        }) => useAxios().axios.post(`/api/users/${userId}/tokens`, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [userTokensBaseKey],
            })
        },
    })
}
