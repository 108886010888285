import type { Ref } from "vue"
import type User from "@/models/User"
import Invitation from "@/models/Invitation"
import useAxios from "@/composables/useAxios"
import { useQuery } from "@tanstack/vue-query"
import type { ApiPaginatedGETResponse, ApiInvitationData } from "types/api"

export const userInvitationsBaseKey = "user-invitation"

export function useUserInvitations(userId: Ref<User["id"] | null>) {
    const query = useQuery({
        queryKey: [userId, userInvitationsBaseKey],
        queryFn: () =>
            useAxios()
                .axios.get<ApiPaginatedGETResponse<ApiInvitationData>>(
                    `/api/invites`,
                    {
                        params: {
                            include: ["organization", "sender"],
                        },
                    }
                )
                .then((data) => data.data.data),
        select(data) {
            return data.map((invitation) => new Invitation(invitation))
        },
        enabled: computed(() => !!userId.value),
    })

    const hasPendingInvite = computed<boolean | undefined>(() =>
        query.data.value?.some((invitation) => {
            return invitation.status === "pending"
        })
    )

    const hasNotViewedInvite = computed<boolean | undefined>(() =>
        query.data.value?.some((invitation) => {
            return invitation.is_viewed === false
        })
    )

    const notViewedInvites = computed<Invitation[] | undefined>(() =>
        query.data.value?.filter((invitation) => {
            return invitation.is_viewed === false
        })
    )

    return {
        ...query,
        hasPendingInvite,
        hasNotViewedInvite,
        notViewedInvites,
    }
}
