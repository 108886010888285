import type { Ref } from "vue"
import { useQuery } from "@tanstack/vue-query"
import type User from "@/models/User"
import type { AxiosError } from "axios"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import OrganizationUser from "@/models/OrganizationUser"
import { organizationsBaseKey } from "./useOrganizationsQuery"
import type { ApiError, ApiOrganizationUserData } from "types/api"

export function useOrganizationUser(
    orgId: Ref<Organization["id"] | "private">,
    userId: Ref<User["id"] | undefined>
) {
    const isPrivateOrg = computed(() => orgId.value === "private")

    const query = useQuery<
        ApiOrganizationUserData,
        AxiosError<ApiError>,
        OrganizationUser
    >({
        queryKey: [organizationsBaseKey, orgId, userId],
        queryFn: () =>
            useAxios()
                .axios.get<ApiOrganizationUserData>(
                    `/api/organizations/${orgId.value}/users/${userId.value}`
                )
                .then((resp) => resp.data),
        select: (data) => new OrganizationUser(data),
        enabled: computed(() => !isPrivateOrg.value && !!userId.value),
    })

    /**
     * True if the current user is an admin for the current organization
     * Will be true if the current organization is the users private org.
     */
    const userIsAdmin = computed(() =>
        isPrivateOrg.value ? true : query.data.value?.role === "admin"
    )

    /**
     * True if the current user is a signee for the current organization.
     */
    const userIsSignee = computed(() =>
        isPrivateOrg.value ? false : query.data.value?.role === "signee"
    )

    /**
     * True if the current user is a signee for the current organization.
     */
    const userIsStandard = computed(() =>
        isPrivateOrg.value ? false : query.data.value?.role === "standard"
    )

    /**
     * The role of the current user for the current organization.
     * If using private org, then this value will be `admin`
     */
    const userRole = computed(() =>
        isPrivateOrg.value ? "admin" : query.data.value?.role
    )

    return {
        ...query,
        userIsAdmin,
        userRole,
        userIsSignee,
        userIsStandard,
    }
}
