import useAxios from "@/composables/useAxios"
import type Invitation from "@/models/Invitation"
import type Organization from "@/models/Organization"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { orgInvitationBaseKey } from "./useOrgInvitationsQuery"

export function useOrgInvitationDelete() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            orgId,
            inviteId,
        }: {
            orgId: Organization["id"]
            inviteId: Invitation["id"]
        }) =>
            useAxios().axios.delete(
                `/api/organizations/${orgId}/invites/${inviteId}`
            ),
        onMutate() {
            // todo make this an optimistic update
        },
        onSettled() {
            queryClient.invalidateQueries({ queryKey: [orgInvitationBaseKey] })
        },
    })
}
