import useAxios from "@/composables/useAxios"
import type Integration from "@/models/Integration"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { integrationsBaseKey } from "./useIntegrations"

export function useIntegrationDelete() {
    const { axios } = useAxios()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ integrationId }: { integrationId: Integration["id"] }) =>
            axios.delete(`/api/integrations/${integrationId}`),

        onSettled() {
            queryClient.invalidateQueries({ queryKey: [integrationsBaseKey] })
        },
    })
}
