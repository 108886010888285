import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import useNotification from "@/composables/useNotification"
import type OrganizationSetting from "@/models/OrganizationSetting"
import { organizationSettingBaseKey } from "./useOrganizationSettingsQuery"

export function useOrganizationSettingsUpdate() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (payload: {
            orgId: Organization["id"]
            setting: keyof OrganizationSetting
            payload: { value: any }
        }) =>
            useAxios().axios.put(
                `/api/organizations/${payload.orgId}/settings/${payload.setting}`,
                { ...payload.payload }
            ),

        onSuccess: () => {
            useNotification().open({
                title: "Success",
                type: "success",
                message: "Organization settings updated",
            })
            queryClient.invalidateQueries({
                queryKey: [organizationSettingBaseKey],
            })
        },
    })
}
