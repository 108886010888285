<template>
    <div class="">
        <SectionHeader
            v-if="label"
            :title="label"
            :description="description"
            class="mb-8"
        >
            <template #actions>
                <slot name="header-action"></slot>
            </template>
        </SectionHeader>

        <div class="flex flex-col px-4 sm:px-6 lg:px-8">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                    class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                >
                    <div
                        ref="table"
                        class="w-full overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                        :class="[
                            sizeHeight === 'full' && 'max-h-full',
                            sizeHeight === 'small' && 'max-h-[25rem]',
                            sizeHeight === 'medium' && 'max-h-[50rem]',
                            sizeHeight === 'large' && 'max-h-[75rem]',
                        ]"
                    >
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead
                                class="sticky top-0 z-10 bg-gray-50 shadow-sm"
                            >
                                <tr>
                                    <th
                                        v-if="reorderable && !isEmpty"
                                        class="m-0 p-0"
                                    ></th>
                                    <th
                                        v-for="(field, index) in cols"
                                        :key="
                                            Array.isArray(field)
                                                ? field[1]
                                                : field
                                        "
                                        scope="col"
                                        class="text-left text-sm font-semibold text-gray-900"
                                        :class="{
                                            'py-3.5 pl-4 pr-3 text-gray-900 sm:pl-6':
                                                index === 0 &&
                                                !reorderable &&
                                                field !== 'actions' &&
                                                field !== 'checkbox',
                                            'px-3 py-3.5 text-gray-900': !(
                                                index === 0 &&
                                                !reorderable &&
                                                field !== 'actions' &&
                                                field !== 'checkbox'
                                            ),
                                            'relative w-1 whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6':
                                                field === 'actions' ||
                                                field === 'checkbox',
                                            ...(Array.isArray(field) &&
                                                field[2] && {
                                                    'hidden sm:table-cell':
                                                        field[2].show === 'sm',
                                                    'hidden md:table-cell':
                                                        field[2].show === 'md',
                                                    'hidden lg:table-cell':
                                                        field[2].show === 'lg',
                                                    'hidden xl:table-cell':
                                                        field[2].show === 'xl',
                                                }),
                                        }"
                                    >
                                        {{
                                            Array.isArray(field)
                                                ? field[1]
                                                : field === "actions" ||
                                                  field === "checkbox"
                                                ? ""
                                                : field
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                ref="items"
                                class="divide-y divide-gray-200 bg-white"
                            >
                                <slot name="first-row"></slot>
                                <!-- Is empty -->
                                <tr
                                    v-if="isEmpty && !initLoading"
                                    class="bg-white"
                                >
                                    <td
                                        :colspan="cols.length"
                                        class="px-4 py-4 text-center text-sm italic text-gray-400"
                                    >
                                        {{ emptyMessage }}
                                    </td>
                                </tr>
                                <tr
                                    v-for="(item, rowIndex) in data"
                                    :key="rowIndex"
                                    :data-id="item.id ?? rowIndex"
                                    class="draggable"
                                    :class="[flashNew(item[flashNewProperty])]"
                                >
                                    <td v-if="reorderable" class="m-0 w-1 p-0">
                                        <GFIcon
                                            icon="bars"
                                            class="handle ml-2 cursor-move rounded p-3 hover:bg-gray-100"
                                        />
                                    </td>
                                    <td
                                        v-for="(field, fieldIndex) in cols"
                                        :key="fieldIndex"
                                        :class="{
                                            'm-0 p-0 ': true,
                                            ...(!noRowStyle && {
                                                [rowClasses]: true,
                                                'whitespace-nowrap': !allowWrap,
                                                'max-w-[15rem] overflow-hidden py-4 text-sm text-gray-500 md:max-w-md': true,
                                                'pl-4 pr-3 sm:pl-6':
                                                    fieldIndex === 0 &&
                                                    !reorderable &&
                                                    field !== 'actions' &&
                                                    field !== 'checkbox',
                                                'px-3': !(
                                                    fieldIndex === 0 &&
                                                    !reorderable &&
                                                    field !== 'actions' &&
                                                    field !== 'checkbox'
                                                ),
                                                'relative w-1 pl-3 pr-4 text-right sm:pr-6':
                                                    field === 'actions',
                                                'relative text-center':
                                                    field === 'checkbox',
                                                ...(Array.isArray(field) &&
                                                    field[2] && {
                                                        'hidden sm:table-cell':
                                                            field[2].show ===
                                                            'sm',
                                                        'hidden md:table-cell':
                                                            field[2].show ===
                                                            'md',
                                                        'hidden lg:table-cell':
                                                            field[2].show ===
                                                            'lg',
                                                        'hidden xl:table-cell':
                                                            field[2].show ===
                                                            'xl',
                                                    }),
                                            }),
                                        }"
                                    >
                                        <slot
                                            :name="
                                                field === 'checkbox'
                                                    ? 'checkbox-column'
                                                    : Array.isArray(field)
                                                    ? field[0]
                                                    : field
                                            "
                                            :field-data="
                                                item[
                                                    Array.isArray(field)
                                                        ? field[0]
                                                        : field
                                                ]
                                            "
                                            :data="
                                                item[
                                                    Array.isArray(field)
                                                        ? field[0]
                                                        : field
                                                ]
                                            "
                                            :row="item"
                                            :index="rowIndex"
                                        >
                                            <div
                                                v-if="field === 'checkbox'"
                                                class=""
                                            >
                                                <slot
                                                    name="checkbox"
                                                    :item="item"
                                                    :row-index="rowIndex"
                                                    :checked="
                                                        itemIsChecked(item)
                                                    "
                                                >
                                                    <GFCheckbox
                                                        class="ml-2"
                                                        :checked="
                                                            itemIsChecked(item)
                                                        "
                                                        :name="
                                                            rowIndex.toString()
                                                        "
                                                        @input="onCheck(item)"
                                                    />
                                                </slot>
                                            </div>
                                            <div
                                                v-else
                                                class="truncate text-gray-500"
                                            >
                                                <span
                                                    v-if="
                                                        hasValidField(
                                                            item,
                                                            field
                                                        )
                                                    "
                                                    >{{
                                                        item[
                                                            Array.isArray(field)
                                                                ? field[0]
                                                                : field
                                                        ]
                                                    }}
                                                </span>
                                                <span
                                                    v-else
                                                    class="select-none italic text-gray-300"
                                                >
                                                    None
                                                </span>
                                            </div>
                                        </slot>
                                    </td>
                                </tr>
                                <div
                                    v-if="hasMoreRows && sizeHeight !== 'full'"
                                    ref="tracker"
                                ></div>
                                <tr v-show="loading || initLoading">
                                    <td
                                        colspan="100%"
                                        class="w-full py-4 text-center"
                                    >
                                        <GFSpinner class="h-10 w-10" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { isEqual } from "lodash"
import Sortable from "sortablejs"
import GFIcon from "./GFIcon.vue"
import type { PropType } from "vue"
import GFSpinner from "./GFSpinner.vue"
import GFCheckbox from "./GFCheckbox.vue"
import { useIntersectionObserver, useScroll } from "@vueuse/core"
import SectionHeader from "../layout/SectionHeader.vue"
import { useDate } from "@/composables/useDate"

type FieldName = string
type DisplayName = string
type Settings = { show: "sm" | "md" | "lg" | "xl" | "all" }
type TableColumn = (FieldName | [FieldName, DisplayName, Settings?])[]
type HeightSize = "small" | "medium" | "large" | "full"

const emit = defineEmits<{
    (event: "scroll-bottom"): void
    (event: "update:selected", selected: Record<any, any>[]): void
    (event: "reorder", selected: string[]): void
    (event: "checked", item: Record<any, any>): void
    (event: "unchecked", item: Record<any, any>): void
}>()

const props = defineProps({
    data: {
        type: Array as PropType<(Record<any, any> | any)[]>,
        default: () => [],
    },
    label: {
        type: String,
        default: null,
    },
    description: {
        type: String,
        default: null,
    },
    cols: {
        type: Array as PropType<TableColumn>,
        default: () => [],
    },
    /** For loading more rows. */
    loading: {
        type: Boolean,
        default: false,
    },
    /** For initially loading the table. */
    initLoading: {
        type: Boolean,
        default: false,
    },
    noRowStyle: {
        type: Boolean,
        default: false,
    },
    sizeHeight: {
        type: String as PropType<HeightSize>,
        default: "full",
    },
    hasMoreRows: {
        type: Boolean,
        default: false,
    },
    allowWrap: {
        type: Boolean,
        default: false,
    },
    rowClasses: {
        type: String,
        default: "",
    },
    emptyMessage: {
        type: String,
        default: "No data",
    },
    selected: {
        type: Array as PropType<Record<string, any>[] | undefined>,
        default: () => [],
    },
    reorderable: {
        type: Boolean,
        default: false,
    },
    /**
     * Function to check if an item is currently checked
     */
    isChecked: {
        type: Function as PropType<(item: Record<string, any>) => boolean>,
        default: () => false,
    },
    flashNewRows: {
        type: Boolean,
        default: false,
    },
    flashNewProperty: {
        type: String,
        default: "created_at",
    },
})

const items = ref<HTMLElement>()
const table = ref<HTMLElement>()
const tracker = ref<HTMLElement>()
const sortable = ref()

useIntersectionObserver(tracker, ([{ isIntersecting }]) => {
    if (isIntersecting) emit("scroll-bottom")
})
const { arrivedState } = useScroll(table)

watch(arrivedState, ({ bottom }) => {
    if (bottom) emit("scroll-bottom")
})

const isEmpty = computed(() => props.data?.length === 0)

watch(items, () => {
    if (!props.reorderable) return

    if (!items.value) {
        sortable.value = null
    } else {
        /**
         * @see https://github.com/SortableJS/Sortable
         */
        sortable.value = new Sortable(items.value, {
            draggable: ".draggable",
            animation: 150, // ms, animation speed moving items when sorting, `0` — without animation
            handle: ".handle", // Restricts sort start click/touch to the specified element
            ghostClass: "ghost", // Class name for the drop placeholder
            dragClass: "dragging", // Class name for the dragging item
            forceFallback: true,
            onSort,
        })
    }
})

function itemIsChecked(item: Record<string, any>) {
    return props.selected?.includes(item)
}

function onCheck(item: Record<string, any>) {
    if (props.selected === undefined) return

    if (itemIsChecked(item)) {
        emit(
            "update:selected",
            props.selected.filter((i) => !isEqual(i, item))
        )
        emit("unchecked", item)
    } else {
        emit("update:selected", [...props.selected, item])
        emit("checked", item)
    }
}

function onSort(_evt: Sortable.SortableEvent) {
    emit("reorder", sortable.value.toArray())
}

/**
 * Checks if a field is either undefined or null.
 */
function hasValidField(
    item: Record<any, any>,
    field: FieldName | [FieldName, DisplayName, Settings?]
) {
    return ![null, undefined].includes(
        item[Array.isArray(field) ? field[0] : field]
    )
}

function flashNew(createdAt: string | undefined | null) {
    if (!createdAt) return
    if (!props.flashNewRows) return

    const secondsToQualifyAsNew = 2

    const { dayjs } = useDate()

    const createdAtDate = dayjs(createdAt)
    const now = dayjs()

    const diff = now.diff(createdAtDate, "second")

    const shouldFlash = diff < secondsToQualifyAsNew

    return shouldFlash ? "flash-new" : ""
}
</script>
<style>
.ghost {
    opacity: 0.3;
}
.dragging {
    @apply bg-white;
}
.flash-new {
    animation: flash-new 1s ease-in-out;
}
@keyframes flash-new {
    0% {
        background-color: #edf2f7;
    }
    100% {
        background-color: #f7fafc;
    }
}
</style>
