import type { Ref } from "vue"
import { useQuery } from "@tanstack/vue-query"
import type User from "@/models/User"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import type { ApiModelSubscriptionData } from "types/api"
import { ModelSubscription } from "@/models/ModelSubscription"
import { QueryKey } from "../QueryKey"
import config from "@/config"

export function useModelSubscriptionQuery(
    modelType: Ref<ModelSubscription["modelType"]>,
    modelId: Ref<User["id"] | Organization["id"] | undefined | null>,
    enabled: Ref<boolean> = ref(true)
) {
    return useQuery({
        queryKey: [QueryKey.base("model-subscriptions"), modelType, modelId],
        queryFn: () =>
            useAxios().axios.get<ApiModelSubscriptionData>(
                `/api/${modelType.value}/${modelId.value}/billing/subscription`
            ),
        select: (resp) =>
            resp.status === 204 ? null : new ModelSubscription(resp.data),
        enabled: computed(() => !!modelId.value && enabled.value),
        staleTime: config.staleTime.long,
    })
}
