import useAxios from "@/composables/useAxios"
import { useQuery } from "@tanstack/vue-query"
import type { ApiSubscriptionPlanPriceData } from "types/api"
import { stripeBaseKey } from "./useStripeProductsQuery"
import type { Ref } from "vue"
import { SubscriptionPlanPrice } from "@/models/SubscriptionPlanPrice"

export function useStripeProductPricesQuery(
    productId: Ref<string | undefined>
) {
    return useQuery({
        queryKey: [stripeBaseKey, productId],
        queryFn: () =>
            useAxios()
                .axios.get<ApiSubscriptionPlanPriceData[]>(
                    `/api/billing/stripe/products/${productId.value}/prices`
                )
                .then((res) => res.data),
        enabled: computed(() => !!productId.value),
        select: (data) => data.map((price) => new SubscriptionPlanPrice(price)),
    })
}
