import useAxios from "@/composables/useAxios"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import type { ApiCreateSubscriptionPlanPayload } from "types/api"
import { subscriptionPlanBaseKey } from "./useSubscriptionPlansQuery"

export function useSubscriptionPlanCreate() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (payload: ApiCreateSubscriptionPlanPayload) =>
            useAxios().axios.post(`/api/billing/subscription-plans`, payload),
        onSuccess() {
            queryClient.invalidateQueries({
                queryKey: [subscriptionPlanBaseKey],
            })
        },
    })
}
