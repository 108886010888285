import { useMutation, useQueryClient } from "@tanstack/vue-query"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import type Invitation from "@/models/Invitation"
import type { ApiCreateInvitationPayload } from "types/api"
import { orgInvitationBaseKey } from "./useOrgInvitationsQuery"

export function useOrgInvitationUpdate() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            orgId,
            payload,
            inviteId,
        }: {
            orgId: Organization["id"]
            payload: ApiCreateInvitationPayload
            inviteId: Invitation["id"]
        }) =>
            useAxios().axios.put(
                `/api/organizations/${orgId}/invites/${inviteId}`,
                payload
            ),

        onSettled() {
            queryClient.invalidateQueries({ queryKey: [orgInvitationBaseKey] })
        },
    })
}
