<template>
    <SectionLayoutForm>
        <GFInput
            ref="keyInputRef"
            v-model="stubKey"
            label="Stub key"
            name="key"
            class="w-full"
            @enter="onSubmit"
        />
        <GFInput
            v-model="stubName"
            label="Stub name"
            name="name"
            class="w-full"
            @enter="onSubmit"
        />
        <GFInput
            v-model="stubDescription"
            label="Stub description"
            name="description"
            class="w-full"
            @enter="onSubmit"
        />

        <template #footer>
            <div
                :class="[
                    'flex items-center justify-end',
                    isEditing ? 'space-x-2' : '',
                ]"
            >
                <GFButtonSimple
                    v-if="isEditing"
                    label="Cancel"
                    @click="reset"
                />
                <GFButton
                    :label="isEditing ? 'Update' : 'Save'"
                    :loading="createIsLoading || editIsLoading"
                    @click="onSubmit"
                />
            </div>
        </template>
    </SectionLayoutForm>
</template>
<script setup lang="ts">
import type Stub from "@/models/Stub"
import GFInput from "../base/GFInput.vue"
import GFButton from "../base/GFButton.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import { useStubEdit } from "@/queries/stubs/useStubEdit"
import useNotification from "@/composables/useNotification"
import { useStubCreate } from "@/queries/stubs/useStubCreate"
import SectionLayoutForm from "../layout/SectionLayoutForm.vue"
import { useBillingModelType } from "@/composables/useBillingModelType"

const emit = defineEmits<{
    (e: "update:editingStub", stub: Stub | null): void
}>()

const props = defineProps<{
    editingStub: Stub | null
}>()

watch(
    () => props.editingStub,
    (editingStub) => {
        if (editingStub) {
            stubKey.value = editingStub.key
            stubName.value = editingStub.name
            stubDescription.value = editingStub.description

            nextTick(() => {
                keyInputRef.value?.focus()
            })
        }
    }
)

const isEditing = computed(() => !!props.editingStub)

const { modelId, modelType } = useBillingModelType()

const stubKey = ref<string | null>(null)
const stubName = ref<string | null>(null)
const stubDescription = ref<string | null>(null)
const keyInputRef = ref<HTMLInputElement | null>(null)

const { mutate: createMutation, isPending: createIsLoading } = useStubCreate()
const { mutate: editMutation, isPending: editIsLoading } = useStubEdit()

function onSubmit() {
    if (isEditing.value) {
        onUpdate()
    } else {
        onSave()
    }
}

function onSave() {
    if (!modelId.value) return

    createMutation(
        {
            modelId: modelId.value,
            modelType: modelType.value,
            payload: {
                key: stubKey.value,
                name: stubName.value,
                description: stubDescription.value,
            },
        },
        { onSuccess: reset, onError }
    )
}

function onUpdate() {
    if (!modelId.value) return
    if (!props.editingStub) return

    editMutation(
        {
            modelId: modelId.value,
            modelType: modelType.value,
            stubId: props.editingStub.id,
            payload: {
                key: stubKey.value,
                name: stubName.value,
                description: stubDescription.value,
            },
        },
        { onSuccess: reset, onError }
    )
}

function reset() {
    stubKey.value = null
    stubName.value = null
    stubDescription.value = null

    emit("update:editingStub", null)

    keyInputRef.value?.focus()
}

function onError(e: any) {
    useNotification().open({
        message: e.message,
        title: "Error",
        type: "error",
    })
}
</script>
