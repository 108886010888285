<template>
    <SectionLayoutForm>
        <div class="flex flex-grow flex-col">
            <div v-if="accessCodeLoading" class="flex justify-center">
                <GFSpinner class="h-8 w-8" />
            </div>

            <template v-else-if="!accessCodeData">
                <label class="mb-1 text-sm font-medium text-gray-700">
                    Access code
                </label>

                <div class="flex h-fit w-full space-x-2">
                    <GFInput
                        v-model="accessCode"
                        name="access_code"
                        :form-errors="formErrors"
                        class="w-full"
                        @enter="onApply"
                    />
                </div>
            </template>

            <div v-else-if="accessCodeData" class="text-gray-700">
                <div class="mt-2 flex space-x-2">
                    <GFTag label="Access code applied" color="green" />
                </div>
            </div>
        </div>

        <template v-if="!accessCodeLoading" #footer>
            <div class="flex justify-end">
                <GFButtonSimple
                    v-if="accessCodeData"
                    label="Remove access code"
                    :loading="isLoadingDelete"
                    @click="removeAccessCode"
                />
                <GFButton
                    v-if="!accessCodeData"
                    label="Apply"
                    :disabled="!accessCode"
                    :loading="isLoadingCreate"
                    @click="onApply"
                />
            </div>
        </template>
    </SectionLayoutForm>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import GFTag from "../base/GFTag.vue"
import type User from "@/models/User"
import GFInput from "../base/GFInput.vue"
import GFButton from "../base/GFButton.vue"
import useAxios from "@/composables/useAxios"
import GFSpinner from "../base/GFSpinner.vue"
import GFFormErrors from "@/helpers/GFFormErrors"
import type Organization from "@/models/Organization"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import type { ApiError, BillingModelType } from "types/api"
import SectionLayoutForm from "../layout/SectionLayoutForm.vue"
import { useAccessCodeCreate } from "@/queries/access-codes/useAccessCodeCreate"
import { useAccessCodeDelete } from "@/queries/access-codes/useAccessCodeDelete"

const props = defineProps({
    modelType: {
        type: String as PropType<BillingModelType>,
        required: true,
    },
    modelId: {
        type: String as PropType<User["id"] | Organization["id"]>,
        required: true,
    },
    accessCodeData: {
        type: Boolean as PropType<null | boolean>,
        default: null,
    },
    accessCodeLoading: {
        type: Boolean,
        required: true,
    },
})

const formErrors = ref<GFFormErrors>(new GFFormErrors())
const accessCode = ref("")

const { mutate: accessCodeCreateMutation, isPending: isLoadingCreate } =
    useAccessCodeCreate()
const { mutate: accessCodeDeleteMutation, isPending: isLoadingDelete } =
    useAccessCodeDelete()

function removeAccessCode() {
    accessCodeDeleteMutation(
        {
            modelType: props.modelType,
            modelId: props.modelId,
        },
        {
            onSuccess: () => {
                reset()
            },
            onError(error) {
                if (useAxios().isAxiosError(error)) {
                    formErrors.value = new GFFormErrors(
                        error.response?.data as ApiError
                    )
                }
            },
        }
    )
}

function onApply() {
    if (!accessCode.value) return

    accessCodeCreateMutation(
        {
            modelType: props.modelType,
            modelId: props.modelId,
            accessCode: accessCode.value,
        },
        {
            onSuccess: () => {
                reset()
            },
            onError(error) {
                if (useAxios().isAxiosError(error)) {
                    formErrors.value = new GFFormErrors(
                        error.response?.data as ApiError
                    )
                }
            },
        }
    )
}

function reset() {
    accessCode.value = ""
    formErrors.value = new GFFormErrors()
}
</script>
