import { useMutation, useQueryClient } from "@tanstack/vue-query"
import type User from "@/models/User"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import type Stub from "@/models/Stub"
import { QueryKey } from "../QueryKey"

export function useStubEdit() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (payload: {
            modelType: Stub["modelType"]
            modelId: User["id"] | Organization["id"]
            stubId: Stub["id"]
            payload: {
                key?: string | null
                name?: string | null
                description?: string | null
            }
        }) =>
            useAxios().axios.put(
                `/api/${payload.modelType}/${payload.modelId}/stubs/${payload.stubId}`,
                {
                    key: payload.payload.key,
                    name: payload.payload.name,
                    description: payload.payload.description,
                }
            ),
        onSuccess() {
            queryClient.invalidateQueries({
                queryKey: QueryKey.baseKey("stubs"),
            })
        },
    })
}
