import useAxios from "@/composables/useAxios"
import { alertBaseKey } from "./useAlertQuery"
import type { ApiCreateAlertPayload } from "types/api"
import { useMutation, useQueryClient } from "@tanstack/vue-query"

export function useAlertCreate() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [alertBaseKey],
        mutationFn: (payload: ApiCreateAlertPayload) =>
            useAxios().axios.post(`/api/system/alerts`, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [alertBaseKey],
            })
        },
    })
}
