import { useQuery } from "@tanstack/vue-query"
import useAxios from "@/composables/useAxios"
import { SubscriptionPlan } from "@/models/SubscriptionPlan"
import type {
    ApiPaginatedGETResponse,
    ApiSubscriptionPlanData,
} from "types/api"
import config from "@/config"

export const subscriptionPlanBaseKey = "subscription-plan"

export function useSubscriptionPlansQuery(options?: {
    filterPublished?: boolean
}) {
    const params = {
        include: "features",
        ...(options?.filterPublished && { "filter[published]": true }),
    }

    return useQuery({
        queryKey: [subscriptionPlanBaseKey],
        queryFn: () =>
            useAxios()
                .axios.get<ApiPaginatedGETResponse<ApiSubscriptionPlanData>>(
                    `api/billing/subscription-plans`,
                    { params }
                )
                .then((res) => res.data.data),
        select: (data) => data.map((plan) => new SubscriptionPlan(plan)),
        staleTime: config.staleTime.long,
    })
}
