<template>
    <SectionLayout
        v-if="organizationStore.orgId !== 'private'"
        id="invite-users"
        title="Invited users"
        :description="description"
        class="mt-8"
        is-table
    >
        <template #actions>
            <GFButton
                v-if="!hasHitMaxSeats"
                label="New invite"
                @click="showNewUserForm = true"
            />
            <GFButton
                v-if="hasHitMaxSeats"
                label="Get more seats"
                @click="
                    $router.push({
                        name: 'billing',
                        hash: '#subscription-plan',
                    })
                "
            />
        </template>

        <GFTable
            :cols="[
                ['email', 'Email'],
                ['role', 'Role'],
                ['status', 'Status'],
                ['human_readable_sent_at', 'Sent'],
                'actions',
            ]"
            :data="invitees ?? []"
        >
            <template #status="{ row }">
                <GFTag :label="row.status" :color="getTagColor(row.status)" />
            </template>

            <template #role="{ row }">
                {{ row.role === "admin" ? "Admin" : null }}
                {{ row.role === "standard" ? "Standard" : null }}
            </template>

            <template #actions="{ row }">
                <div class="-mr-2 flex space-x-2">
                    <GFButtonIcon
                        v-tooltip="'Edit'"
                        class="h-7 w-7"
                        rem-size="1.05"
                        icon="pen-to-square"
                        @click="
                            () => {
                                editingInvite = row
                                showNewUserForm = true
                            }
                        "
                    />

                    <GFButtonIcon
                        v-tooltip="'Resend invite'"
                        class="h-7 w-7"
                        rem-size="1.05"
                        icon="paper-plane"
                        :loading="isResending && isResendingInvite === row.id"
                        @click="resend(row)"
                    />

                    <GFButtonIcon
                        v-tooltip="'Delete'"
                        icon="times"
                        :loading="isDeleting && isDeletingInvite === row.id"
                        class="h-7 w-7 text-red-500 hover:text-red-700"
                        rem-size="1.05"
                        @click="onDelete(row.id)"
                    />
                </div>
            </template>
        </GFTable>
    </SectionLayout>

    <GFModalSimple
        v-model:show="showNewUserForm"
        title="Invite user"
        @closed="reset"
    >
        <div class="flex flex-col space-y-2 md:w-96">
            <GFInput
                v-model="email"
                label="Email"
                autofocus
                name="email"
                :form-errors="formErrors"
                @enter="save"
            />

            <GFSelectNative
                v-model="selectedRole"
                label="Role"
                :options="options"
                name="role"
                :form-errors="formErrors"
            />

            <GFInputTextArea
                v-model="message"
                label="Optional message"
                name="optional_message"
                :form-errors="formErrors"
            />
        </div>
        <template #buttons="{ close: closeCreate }">
            <div class="flex space-x-2">
                <GFButtonSimple label="Cancel" @click="closeCreate" />

                <GFButton
                    v-if="!editingInvite"
                    label="Send invite"
                    :loading="isCreating"
                    @click="save"
                />

                <GFButtonSimple
                    v-if="editingInvite"
                    label="Save"
                    :loading="isUpdating"
                    @click="update"
                />

                <GFButton
                    v-if="editingInvite"
                    label="Save and send"
                    :loading="isCreating || isResending"
                    @click="saveAndSend()"
                />
            </div>
        </template>
    </GFModalSimple>
</template>
<script setup lang="ts">
import type {
    ApiCreateInvitationPayload,
    ApiError,
    InvitationRole,
} from "types/api"
import type { PropType } from "vue"
import useAxios from "@/composables/useAxios"
import GFTag from "@/components/base/GFTag.vue"
import GFFormErrors from "@/helpers/GFFormErrors"
import type Invitation from "@/models/Invitation"
import GFInput from "@/components/base/GFInput.vue"
import GFTable from "@/components/base/GFTable.vue"
import GFButton from "@/components/base/GFButton.vue"
import useNotification from "@/composables/useNotification"
import { useOrganizationStore } from "@/stores/organization"
import GFButtonIcon from "@/components/base/GFButtonIcon.vue"
import type OrganizationUser from "@/models/OrganizationUser"
import GFModalSimple from "@/components/base/GFModalSimple.vue"
import GFButtonSimple from "@/components/base/GFButtonSimple.vue"
import SectionLayout from "@/components/layout/SectionLayout.vue"
import GFSelectNative from "@/components/base/GFSelectNative.vue"
import GFInputTextArea from "@/components/base/GFInputTextArea.vue"
import type { GFSelectOption } from "@/components/base/GFSelect.vue"
import { useBillingModelType } from "@/composables/useBillingModelType"
import { useOrgInvitationCreate } from "@/queries/org-invitations/useOrgInvitationCreate"
import { useOrgInvitationDelete } from "@/queries/org-invitations/useOrgInvitationDelete"
import { useOrgInvitationResend } from "@/queries/org-invitations/useOrgInvitationResend"
import { useOrgInvitationUpdate } from "@/queries/org-invitations/useOrgInvitationUpdate"
import { useModelSubscriptionQuery } from "@/queries/subscription/useModelSubscriptionQuery"

const props = defineProps({
    users: {
        type: Array as PropType<OrganizationUser[]>,
        required: true,
    },
    invitees: {
        type: Array as PropType<Invitation[]>,
        required: true,
    },
})

const options: GFSelectOption<string, InvitationRole>[] = [
    {
        label: "Standard",
        value: "standard",
    },
    {
        label: "Admin",
        value: "admin",
    },
]

const { modelId, modelType } = useBillingModelType()
const organizationStore = useOrganizationStore()
const { orgIdString } = storeToRefs(organizationStore)

const selectedRole = ref<InvitationRole | null>(options[0].value)
const message = ref<string | null>(null)
const email = ref<string | null>(null)
const formErrors = ref<GFFormErrors | null>(null)
const showNewUserForm = ref<boolean>(false)
const isDeletingInvite = ref<Invitation["id"] | null>(null)
const isResendingInvite = ref<Invitation["id"] | null>(null)
const editingInvite = ref<Invitation | null>(null)

const { data: subscription } = useModelSubscriptionQuery(modelType, modelId)

const totalSeats = computed(() => subscription.value?.quantity ?? 0)
const invitesCount = computed(() => props.invitees?.length ?? 0)
const usersCount = computed(() => props.users.length)
const seatsUsed = computed(() => invitesCount.value + usersCount.value)
const seatsRemaining = computed(() => {
    if (!props.invitees) return null
    return totalSeats.value - (invitesCount.value + usersCount.value)
})
const hasHitMaxSeats = computed(() => seatsRemaining.value === 0)

const description = computed(() => {
    // This is if there is no active subscription
    if (!subscription.value) return "Invite new users to your organization."

    if (hasHitMaxSeats.value)
        return (
            (invitesCount.value > 0
                ? "Invited users count towards your # of purchased seats. "
                : "") + "You have reached your maximum number of seats."
        )

    // This should never happen, but just in case
    if (!seatsRemaining.value) return "Invite new users to your organization."

    // This should never happen, but just in case
    if (seatsRemaining.value < 0)
        return "You have exceeded your maximum number of seats. Please contact support to resolve this issue."

    return `Invite new users to your organization. You have used ${seatsUsed.value} of ${totalSeats.value} seats.`
})

const { mutate: createInvitation, isPending: isCreating } =
    useOrgInvitationCreate()
const { mutateAsync: updateInvitation, isPending: isUpdating } =
    useOrgInvitationUpdate()
const { mutate: resendInvitation, isPending: isResending } =
    useOrgInvitationResend()
const { mutate: deleteInvitation, isPending: isDeleting } =
    useOrgInvitationDelete()

const invitePayload = computed<Record<string, string | null>>(() => ({
    email: email.value,
    ...(message.value && { invite_message: message.value }),
    role: selectedRole.value,
}))

watch(editingInvite, () => {
    if (editingInvite.value) {
        email.value = editingInvite.value.email
        selectedRole.value = editingInvite.value.role
        message.value = editingInvite.value.invite_message
    }
})

function reset() {
    selectedRole.value = options[0].value
    message.value = null
    email.value = null
    formErrors.value = null
    editingInvite.value = null
}

function save() {
    if (orgIdString.value)
        createInvitation(
            {
                orgId: orgIdString.value,
                // Forcing type and letting api errors validate the payload
                payload: invitePayload.value as ApiCreateInvitationPayload,
            },
            {
                onError(error) {
                    if (useAxios().isAxiosError(error)) {
                        formErrors.value = new GFFormErrors(
                            error.response?.data as ApiError
                        )
                    }
                },
                onSuccess() {
                    showNewUserForm.value = false
                    useNotification().open({
                        message: "Invitation sent to " + email.value,
                        title: "Success",
                        type: "success",
                    })
                },
            }
        )
}

async function update() {
    if (orgIdString.value && editingInvite.value)
        await updateInvitation(
            {
                orgId: orgIdString.value,
                // Forcing type and letting api errors validate the payload
                payload: invitePayload.value as ApiCreateInvitationPayload,
                inviteId: editingInvite.value.id,
            },
            {
                onError(error) {
                    if (useAxios().isAxiosError(error)) {
                        formErrors.value = new GFFormErrors(
                            error.response?.data as ApiError
                        )
                    }
                },
                onSuccess() {
                    showNewUserForm.value = false
                },
            }
        )
}

function onDelete(inviteId: Invitation["id"]) {
    isDeletingInvite.value = inviteId
    if (orgIdString.value)
        deleteInvitation(
            { orgId: orgIdString.value, inviteId },
            {
                onSettled() {
                    isDeletingInvite.value = null
                },
                onSuccess() {
                    useNotification().open({
                        message: "Invitation deleted",
                        title: "Success",
                        type: "success",
                    })
                },
            }
        )
}

function resend(invite: Invitation) {
    isResendingInvite.value = invite.id
    if (orgIdString.value)
        resendInvitation(
            { orgId: orgIdString.value, inviteId: invite.id },
            {
                onSettled() {
                    isResendingInvite.value = null
                },
                onSuccess() {
                    useNotification().open({
                        message: "Invitation resent to " + invite.email,
                        title: "Success",
                    })
                },
            }
        )
}

async function saveAndSend() {
    if (editingInvite.value) {
        await update()
        resend(editingInvite.value)
    }
}

function getTagColor(status: Invitation["status"]) {
    switch (status) {
        case "pending":
            return "yellow"
        case "not sent":
            return "red"
        case "rejected":
            return "red"
    }
}
</script>
