import { useQuery } from "@tanstack/vue-query"
import useAxios from "@/composables/useAxios"
import Integration from "@/models/Integration"
import type { ApiPaginatedGETResponse, ApiIntegrationData } from "types/api"

export const companyCamBaseKey = "company-cam"
export const integrationsBaseKey = "integrations"

export function useIntegrations() {
    return useQuery({
        queryKey: [integrationsBaseKey],

        queryFn: () =>
            useAxios()
                .axios.get<ApiPaginatedGETResponse<ApiIntegrationData>>(
                    `/api/integrations`
                )
                .then(({ data }) => data.data),

        select: (data) => data.map((int) => new Integration(int)),

        staleTime: 0,
    })
}
