import type User from "@/models/User"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import useNotification from "@/composables/useNotification"
import type { ModelSubscription } from "@/models/ModelSubscription"
import { QueryKey } from "../QueryKey"

export function usePromoCodeApply() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (payload: {
            modelType: ModelSubscription["modelType"]
            modelId: User["id"] | Organization["id"]
            promoCode: string
        }) =>
            useAxios().axios.put(
                `/api/${payload.modelType}/${payload.modelId}/billing/subscription/promo-code`,
                {
                    promo_code: payload.promoCode,
                }
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: QueryKey.baseKey("model-subscriptions"),
            })
            queryClient.invalidateQueries({
                queryKey: QueryKey.baseKey("invoices"),
            })

            useNotification().open({
                type: "success",
                title: "Promo code applied",
                message: "Your promo code has been applied. Thank you!",
            })
        },
    })
}
