import useAxios from "@/composables/useAxios"
import { QueryClient, useInfiniteQuery, useQuery } from "@tanstack/vue-query"
import type { ApiNotificationData, ApiPaginatedGETResponse } from "types/api"
import { Notification } from "@/models/Notification"
import { QueryKey } from "../QueryKey"
import type { Ref } from "vue"

export type NotificationQueryKey = {
    all?: Ref<boolean>
    onlyCount?: Ref<boolean>
}

export function useNotificationsInfiniteQuery() {
    const key = new QueryKey<NotificationQueryKey>("notifications", {
        all: ref(true),
    })

    const total = ref<number | undefined>(undefined)

    const query = useInfiniteQuery({
        initialPageParam: 1,

        queryKey: key.key,

        queryFn: ({ pageParam = 1 }) => {
            return useAxios()
                .axios.get<ApiPaginatedGETResponse<ApiNotificationData>>(
                    `/api/notifications`,
                    {
                        params: {
                            "page[number]": pageParam,
                        },
                    }
                )
                .then((resp) => resp.data)
        },

        select({ pageParams, pages }) {
            total.value = pages[0]?.total

            const transformedPages = pages.map((page) => {
                return page.data.map(
                    (notificationData) => new Notification(notificationData)
                )
            })

            return { pages: transformedPages, pageParams }
        },

        getNextPageParam: (lastPage, _pages) =>
            lastPage.next_page_url ? lastPage.current_page + 1 : undefined,
    })

    const notifications = computed(() => query.data.value?.pages.flat())

    const hasUnreadNotifications = computed(() => {
        return notifications.value?.some((notification) => !notification.isRead)
    })

    const hasNotifications = computed(() => {
        return (
            !query.isLoading.value &&
            notifications.value &&
            notifications.value.length > 0
        )
    })

    const hasMoreThanOneNotification = computed(() => {
        return (
            !query.isLoading.value &&
            notifications.value &&
            notifications.value.length > 1
        )
    })

    return {
        ...query,
        notifications,
        total,
        hasNotifications,
        hasUnreadNotifications,
        hasMoreThanOneNotification,
    }
}

export function useNotificationsUnreadCount() {
    const key = new QueryKey<NotificationQueryKey>("notifications", {
        onlyCount: ref(true),
    })

    return useQuery({
        queryKey: key.key,
        queryFn: () =>
            useAxios()
                .axios.get<ApiPaginatedGETResponse<ApiNotificationData[]>>(
                    `/api/notifications`,
                    { params: { "filter[status]": "unread", "page[size]": 1 } }
                )
                .then((resp) => resp.data),

        select: (data) => data.total,
    })
}

export function refreshNotifications(queryClient: QueryClient) {
    const allKey = new QueryKey<NotificationQueryKey>("notifications", {
        all: ref(true),
    })

    const onlyCountKey = new QueryKey<NotificationQueryKey>("notifications", {
        onlyCount: ref(true),
    })

    queryClient.invalidateQueries({ queryKey: allKey.key })
    queryClient.invalidateQueries({ queryKey: onlyCountKey.key })
}
