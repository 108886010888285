import useAxios from "@/composables/useAxios"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import useNotification from "@/composables/useNotification"
import type { PaymentMethod } from "@/models/PaymentMethod"
import type { BillingModelId, BillingModelType } from "types/api"
import { QueryKey } from "../QueryKey"

export function usePaymentMethodDelete() {
    const queryClient = useQueryClient()
    const notification = useNotification()

    return useMutation({
        mutationFn: (payload: {
            modelType: BillingModelType
            modelId: BillingModelId
            paymentMethodId: PaymentMethod["id"]
        }) =>
            useAxios().axios.delete(
                `/api/${payload.modelType}/${payload.modelId}/billing/payment-methods/` +
                    payload.paymentMethodId
            ),
        onSuccess() {
            notification.open({
                type: "success",
                title: "Payment method removed",
                message: "Your payment method has been successfully removed.",
            })
            queryClient.invalidateQueries({
                queryKey: QueryKey.baseKey("payment-methods"),
            })
        },
    })
}
