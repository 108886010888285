import useAxios from "@/composables/useAxios"
import type { ApiUpdateUserPayload } from "types/api"
import { useMutation } from "@tanstack/vue-query"

export function useUserUpdate() {
    return useMutation({
        mutationFn: (payload: ApiUpdateUserPayload) => {
            return useAxios().axios.put("/api/users/me", payload)
        },
    })
}
