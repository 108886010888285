<template>
    <div class="relative">
        <div
            v-if="hasAccessCode"
            class="absolute top-0 left-0 z-10 m-0 flex h-full w-full items-center justify-center rounded-lg bg-gray-900/20 p-0"
        >
            <div
                class="rounded bg-gray-100 p-4 text-lg font-medium text-gray-900"
            >
                Access code applied
            </div>
        </div>
        <SectionLayoutForm :blur="hasAccessCode">
            <div v-if="loading" class="flex items-center justify-center">
                <GFSpinner class="h-10 w-10" />
            </div>

            <template v-else-if="plans">
                <CurrentSubscriptionBanner
                    v-if="!subscriptionLoading"
                    :is-org="isOrg"
                    :subscription="subscription ?? null"
                />

                <div class="flex flex-col space-y-2">
                    <div class="flex w-full justify-start">
                        <GFToggleSlider
                            v-model="intervalInput"
                            class="w-fit"
                            left-value="monthly"
                            left="Monthly billing"
                            right-value="yearly"
                            right="Annual billing"
                            :disabled="subscription?.changeIntervalDisabled"
                            position="right"
                            use-primary
                        >
                            <template #label>
                                <span
                                    class="flex items-center text-sm font-medium text-gray-900"
                                >
                                    Annual billing
                                </span>
                            </template>
                        </GFToggleSlider>
                    </div>

                    <GFRadioGroupTable
                        v-model:selected="selectedPlan"
                        :items="plans"
                        :cols="4"
                        :label-span="1"
                        :disabled="subscription?.changePlanDisabled"
                    >
                        <template #label="{ row: plan }">
                            {{ plan.name }}
                        </template>

                        <template #default="{ row: plan }">
                            <RadioGroupDescription
                                as="span"
                                class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center"
                            >
                                <div v-show="!annualBillingEnabled">
                                    <span v-if="plan.priceMonthly">
                                        {{
                                            plan.costStringMonthlyComparison(
                                                modelType,
                                                "monthly",
                                                userQuantity
                                            )
                                        }}
                                    </span>

                                    <span v-else class="italic text-gray-400">
                                        None
                                    </span>
                                </div>
                                <div v-show="annualBillingEnabled">
                                    <span v-if="plan.priceYearly">
                                        {{
                                            plan.costStringMonthlyComparison(
                                                modelType,
                                                "yearly",
                                                userQuantity
                                            )
                                        }}
                                    </span>

                                    <span v-else class="italic text-gray-400">
                                        None
                                    </span>
                                </div>
                            </RadioGroupDescription>

                            <RadioGroupDescription
                                as="span"
                                class="col-span-2 ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
                            >
                                {{ plan.description }}
                            </RadioGroupDescription>
                        </template>
                    </GFRadioGroupTable>
                </div>

                <div
                    v-if="isOrg && !resumablePlanSelected"
                    class="flex items-end space-x-4"
                >
                    <GFNumberInput
                        v-model="userQuantityInput"
                        type="number"
                        label="Number of seats"
                        :min="
                            subscription?.decrementDisabled
                                ? subscription.quantity
                                : 1
                        "
                        :max="
                            subscription?.incrementDisabled
                                ? subscription.quantity
                                : null
                        "
                        class="border-none p-0"
                    />

                    <GFButtonSimple
                        label="Manage users"
                        @click="$router.push({ hash: '#invite-users' })"
                    />
                </div>
            </template>

            <template v-if="!loading" #footer>
                <div class="flex flex-row-reverse justify-between">
                    <div>
                        <GFButton
                            v-if="confirmModalStatus === 'new'"
                            :label="
                                subscription?.is_restartable
                                    ? `Start subscription`
                                    : `Start 14-day free trial`
                            "
                            :disabled="!selectedPlan"
                            @click="onSubscribe"
                        />

                        <GFButton
                            v-else-if="confirmModalStatus === 'resume'"
                            label="Resume subscription"
                            :loading="resumeSubscriptionLoading"
                            @click="onResume"
                        />

                        <GFButton
                            v-else
                            label="Update subscription"
                            :disabled="!hasChanges"
                            @click="openConfirmSubscribeModal"
                        />
                    </div>

                    <GFButtonText
                        v-if="subscription?.isCancelable"
                        type="danger"
                        size="sm"
                        :loading="cancelSubscriptionLoading"
                        label="Cancel subscription"
                        @click="openConfirmCancelModal"
                    />
                </div>
            </template>

            <GFModalSimple title="Are you sure?" :show="confirmCancelModal">
                <div class="flex flex-col space-y-3">
                    <p v-if="isOrg">
                        This will cancel your organizations subscription.
                    </p>

                    <p v-else>This will cancel your subscription.</p>

                    <p>
                        Your subscription will be cancelled at the end of your
                        billing period ({{
                            subscription?.renewsAtReadableDate
                        }}).
                    </p>
                </div>

                <template #buttons>
                    <div class="flex w-full justify-between">
                        <GFButtonSimple
                            label="Close"
                            outline
                            @click="closeConfirmCancelModal"
                        />

                        <GFButton
                            label="Cancel subscription"
                            danger
                            @click="onCancel"
                        />
                    </div>
                </template>
            </GFModalSimple>

            <GFModalSimpleIcon
                v-model:show="showInvalidSeatCountUpdateModal"
                title="Error"
                icon="times-circle"
                :message="`The number of seats cannot be less than the number of users or invitees in your organization (${currentSeatOccupancy} users/invitees).`"
                danger
            >
                <template #buttons="{ close }">
                    <div class="flex w-full items-center justify-between">
                        <GFButtonSimple
                            label="Manage users"
                            @click="
                                () => {
                                    $router.push({
                                        hash: '#organization-users',
                                    })
                                    close()
                                }
                            "
                        />
                        <GFButtonSimple label="Close" @click="close" />
                    </div>
                </template>
            </GFModalSimpleIcon>

            <SubscriptionConfirmModalVue
                :show="confirmSubscribeModal"
                :plan="selectedPlan"
                :model-type="modelType"
                :model-id="modelId"
                :interval="intervalInput"
                :user-quantity="userQuantity"
                :default-payment-method="defaultPaymentMethod"
                :confirm-is-loading="
                    createSubscriptionLoading || swapSubscriptionLoading
                "
                :status="confirmModalStatus"
                @confirm="onConfirmSubscribe"
                @swap="onSwap"
                @cancel="closeConfirmSubscribeModal"
            />
        </SectionLayoutForm>
    </div>
</template>

<script setup lang="ts">
import SubscriptionConfirmModalVue, {
    type ConfirmModalStatus,
} from "@/components/modals/SubscriptionConfirmModal.vue"
import type { PropType } from "vue"
import type User from "@/models/User"
import GFButton from "../base/GFButton.vue"
import { useUserStore } from "@/stores/user"
import GFSpinner from "../base/GFSpinner.vue"
import GFButtonText from "../base/GFButtonText.vue"
import type Organization from "@/models/Organization"
import GFModalSimple from "../base/GFModalSimple.vue"
import GFNumberInput from "../base/GFNumberInput.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import GFToggleSlider from "../base/GFToggleSlider.vue"
import { RadioGroupDescription } from "@headlessui/vue"
import { useOrganizationStore } from "@/stores/organization"
import GFRadioGroupTable from "../base/GFRadioGroupTable.vue"
import GFModalSimpleIcon from "../base/GFModalSimpleIcon.vue"
import SectionLayoutForm from "../layout/SectionLayoutForm.vue"
import type { SubscriptionPlan } from "@/models/SubscriptionPlan"
import type { ModelSubscription } from "@/models/ModelSubscription"
import CurrentSubscriptionBanner from "./CurrentSubscriptionBanner.vue"
import type { BillingModelType, SubscriptionPlanPriceInterval } from "types/api"
import { usePaymentMethodsQuery } from "@/queries/payment-methods/usePaymentMethodsQuery"
import { useModelSubscriptionSwap } from "@/queries/subscription/useModelSubscriptionSwap"
import { useModelSubscriptionResume } from "@/queries/subscription/useModelSubscriptionResume"
import { useModelSubscriptionCancel } from "@/queries/subscription/useModelSubscriptionCancel"
import { useModelSubscriptionCreate } from "@/queries/subscription/useModelSubscriptionCreate"
import { useSubscriptionPlansQuery } from "@/queries/subscription-plans/useSubscriptionPlansQuery"

const emit = defineEmits<{
    (e: "add-payment-method", cb: () => void): void
}>()

const props = defineProps({
    subscription: {
        type: [Object, null] as PropType<ModelSubscription | null>,
        required: true,
    },
    subscriptionRefetching: {
        type: Boolean,
        required: true,
    },
    subscriptionLoading: {
        type: Boolean,
        required: true,
    },
    hasAccessCode: {
        type: Boolean,
        required: true,
    },
    currentSeatOccupancy: {
        type: Number,
        required: true,
    },
})
const { subscription, subscriptionRefetching, subscriptionLoading } =
    toRefs(props)

const { user } = storeToRefs(useUserStore())
const { orgId, orgIdString } = storeToRefs(useOrganizationStore())

const isOrg = computed(() => orgId.value !== "private")
const modelType = computed<BillingModelType>(() =>
    isOrg.value ? "organizations" : "users"
)
const modelId = computed(() =>
    isOrg.value
        ? (orgIdString.value as Organization["id"])
        : (user.value?.id as User["id"])
)

const { data: paymentMethod } = usePaymentMethodsQuery(modelType, modelId)
const {
    data: plansData,
    isLoading: planLoading,
    isRefetching: planRefetching,
} = useSubscriptionPlansQuery({ filterPublished: true })

const plans = computed(
    () => plansData.value?.filter((plan) => plan.isStripePlan) ?? []
)

const confirmModalStatus = computed<ConfirmModalStatus>(() => {
    if (subscription.value === null || subscription.value.is_restartable)
        return "new"

    if (resumablePlanSelected.value) return "resume"

    return "update"
})

const {
    mutate: cancelSubscriptionMutation,
    isPending: cancelSubscriptionLoading,
} = useModelSubscriptionCancel()
const {
    mutate: resumeSubscriptionMutation,
    isPending: resumeSubscriptionLoading,
} = useModelSubscriptionResume()
const { mutate: swapSubscriptionMutation, isPending: swapSubscriptionLoading } =
    useModelSubscriptionSwap()
const {
    mutate: createSubscriptionMutation,
    isPending: createSubscriptionLoading,
} = useModelSubscriptionCreate()

const interval = ref<SubscriptionPlanPriceInterval>("monthly")
const intervalInput = computed<SubscriptionPlanPriceInterval>({
    get: () => interval.value,
    set: (value) => {
        annualBillingEnabled.value = value === "yearly"
        interval.value = value
    },
})
const selectedPlan = ref<SubscriptionPlan | null>(null)
const annualBillingEnabled = ref(interval.value === "yearly")
const confirmCancelModal = ref(false)
const showInvalidSeatCountUpdateModal = ref(false)
const confirmSubscribeModal = ref(false)
const userQuantity = ref<number>(1)
const userQuantityInput = computed({
    get: () => userQuantity.value,
    set: (value: string | number) => {
        userQuantity.value = typeof value === "string" ? parseInt(value) : value
    },
})
const newSeatCountLowerThanUserCount = computed(() => {
    return userQuantityInput.value < props.currentSeatOccupancy
})

const loading = computed(
    () =>
        subscriptionLoading.value ||
        planLoading.value ||
        planRefetching.value ||
        subscriptionRefetching.value
)
const needsPaymentMethod = computed(() => !paymentMethod.value?.length)
const defaultPaymentMethod = computed(() => {
    if (!paymentMethod.value) return null
    return paymentMethod.value.find((p) => p.is_default)
})

const currentSubscriptionQuantity = computed(() => subscription.value?.quantity)
const currentSubscriptionStripeProductId = computed(
    () => subscription.value?.stripeProductId
)
const currentSubscriptionStripePriceId = computed(
    () => subscription.value?.stripe_price.id
)

const hasChanges = computed(() => {
    return (
        currentSubscriptionStripeProductId.value !==
            selectedPlan.value?.stripeProductId ||
        currentSubscriptionStripePriceId.value !==
            selectedPlan.value?.getPriceFromInterval(intervalInput.value)?.id ||
        currentSubscriptionQuantity.value !== userQuantity.value
    )
})

const isCurrentPlanSelected = computed(() => {
    if (!selectedPlan.value) return false
    if (!subscription) return false

    return (
        selectedPlan.value.stripeProductId ===
        subscription.value?.stripeProductId
    )
})

const resumablePlanSelected = computed(
    () => subscription.value?.isResumable && isCurrentPlanSelected.value
)

watch([plans, subscription], ([plans, subscription]) => {
    setup([plans, subscription])
})

onMounted(() => {
    setup([plans.value, props.subscription])
})

function setup([plans, subscription]: [
    SubscriptionPlan[],
    ModelSubscription | null
]) {
    if (!plans) return

    if (!subscription) {
        /**
         * User is not subscribed
         */
        selectedPlan.value = plans[0]

        return
    }

    const index = plans.findIndex(
        (plan) => plan.stripeProductId === subscription.stripeProductId
    )

    if (index === -1) return

    selectedPlan.value = plans[index]
    annualBillingEnabled.value = subscription.interval === "yearly"
    userQuantity.value = subscription.quantity

    // We are casting here because we really don't know what the interval is
    // since it comes from stripe. We are just assuming that it is either
    // monthly or yearly.
    intervalInput.value = subscription.interval as SubscriptionPlanPriceInterval
}

function onSwap(promoCode?: string | null) {
    if (!selectedPlan.value) return

    swapSubscriptionMutation(
        {
            modelType: modelType.value,
            modelId: modelId.value,
            payload: {
                subscription_plan_id: selectedPlan.value?.id,
                interval: annualBillingEnabled.value ? "yearly" : "monthly",
                ...(isOrg.value ? { users: userQuantity.value } : {}),
                ...(promoCode && { promo_code: promoCode }),
            },
        },
        { onSuccess: closeConfirmSubscribeModal }
    )
}

async function onResume() {
    const resume = () =>
        resumeSubscriptionMutation({
            modelId: modelId.value,
            modelType: modelType.value,
        })

    if (needsPaymentMethod.value) setUpPaymentMethod(resume)
    else resume()
}

function onCancel() {
    closeConfirmCancelModal()
    cancelSubscriptionMutation({
        modelType: modelType.value,
        modelId: modelId.value,
    })
}

async function onSubscribe() {
    if (!selectedPlan.value) return
    const subscribe = () => openConfirmSubscribeModal()

    if (needsPaymentMethod.value) setUpPaymentMethod(subscribe)
    else subscribe()
}

function onConfirmSubscribe(promoCode?: string | null) {
    if (!selectedPlan.value) return

    createSubscriptionMutation(
        {
            modelType: modelType.value,
            modelId: modelId.value,
            payload: {
                interval: annualBillingEnabled.value ? "yearly" : "monthly",
                subscription_plan_id: selectedPlan.value.id,
                ...(isOrg.value ? { users: userQuantity.value } : {}),
                ...(promoCode && { promo_code: promoCode }),
            },
        },
        { onSuccess: closeConfirmSubscribeModal }
    )
}

function openConfirmCancelModal() {
    confirmCancelModal.value = true
}

function closeConfirmCancelModal() {
    confirmCancelModal.value = false
}

function openConfirmSubscribeModal() {
    if (newSeatCountLowerThanUserCount.value) {
        showInvalidSeatCountUpdateModal.value = true
        return
    }

    confirmSubscribeModal.value = true
}

function closeConfirmSubscribeModal() {
    confirmSubscribeModal.value = false
}

function setUpPaymentMethod(cb: () => void) {
    emit("add-payment-method", cb)
}
</script>
