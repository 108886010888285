import type {
    ApiModelSubscriptionPreviewData,
    SubscriptionPlanPriceInterval,
} from "types/api"
import type User from "@/models/User"
import { useMutation } from "@tanstack/vue-query"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import type { SubscriptionPlan } from "@/models/SubscriptionPlan"
import type { ModelSubscription } from "@/models/ModelSubscription"

interface Payload {
    subscription_plan_id: SubscriptionPlan["id"]
    interval: SubscriptionPlanPriceInterval
    users?: number
    promo_code?: string
}

export function useModelSubscriptionPreview() {
    return useMutation({
        mutationFn: (payload: {
            modelType: ModelSubscription["modelType"]
            modelId: User["id"] | Organization["id"]
            payload: Payload
        }) =>
            useAxios()
                .axiosInstanceDangerous.post<ApiModelSubscriptionPreviewData>(
                    `/api/${payload.modelType}/${payload.modelId}/billing/subscription/preview`,
                    payload.payload
                )
                .then((resp) => resp.data),
    })
}
