<template>
    <SectionLayoutForm>
        <div class="flex flex-grow flex-col">
            <div
                v-if="subscription.applied_promo_code"
                class="mb-4 text-sm font-medium text-gray-700"
            >
                <span>Current promo code: </span>

                <span class="font-bold">
                    {{ subscription.applied_promo_code.name }}
                </span>
            </div>

            <label class="mb-1 text-sm font-medium text-gray-700">
                Promo code
            </label>

            <div v-if="!promoCodeData" class="flex h-fit w-full space-x-2">
                <GFInput
                    v-model="promoCode"
                    name="promoCode"
                    :form-errors="formErrors"
                    class="w-full"
                    @enter="findPromoCode"
                />

                <GFButtonSimple
                    label="Search"
                    class="h-fit"
                    :loading="promoCodeLoading"
                    @click="findPromoCode"
                />
            </div>

            <div v-if="promoCodeData" class="text-gray-700">
                <GFTag color="green" class="flex items-center space-x-2">
                    <span>{{ promoCodeData.code }}</span>
                    <GFButtonIcon
                        icon="times-circle"
                        class="bg-green-200 text-green-900 hover:text-red-500"
                        @click="removePromoCode"
                    />
                </GFTag>

                <div class="mt-2 flex space-x-2">
                    <span class="text-sm font-medium">
                        {{ promoCodeData.name }}:
                    </span>
                    <span
                        v-if="promoCodeData.amount_off"
                        class="text-sm font-medium"
                    >
                        ${{ promoCodeData.amount_off / 100 }} off
                    </span>
                    <span
                        v-if="promoCodeData.percent_off"
                        class="text-sm font-medium"
                    >
                        {{ promoCodeData.percent_off }}% off
                    </span>
                </div>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-end">
                <GFButton
                    label="Apply"
                    :disabled="!promoCodeData?.is_valid"
                    :loading="isLoadingApply"
                    @click="onApply"
                />
            </div>
        </template>
    </SectionLayoutForm>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import GFTag from "../base/GFTag.vue"
import type User from "@/models/User"
import GFInput from "../base/GFInput.vue"
import GFButton from "../base/GFButton.vue"
import type { BillingModelType } from "types/api"
import GFFormErrors from "@/helpers/GFFormErrors"
import GFButtonIcon from "../base/GFButtonIcon.vue"
import type Organization from "@/models/Organization"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import SectionLayoutForm from "../layout/SectionLayoutForm.vue"
import type { ModelSubscription } from "@/models/ModelSubscription"
import { usePromoCodeApply } from "@/queries/promo-codes/usePromoCodeApply"
import { usePromoCodeFindQuery } from "@/queries/promo-codes/usePromoCodeFindQuery"

const props = defineProps({
    modelType: {
        type: String as PropType<BillingModelType>,
        required: true,
    },
    modelId: {
        type: String as PropType<User["id"] | Organization["id"]>,
        required: true,
    },
    subscription: {
        type: Object as PropType<ModelSubscription>,
        default: null,
    },
})

const formErrors = ref<GFFormErrors>(new GFFormErrors())
const promoCode = ref("")
const promoCodeSearchable = ref<null | string>(null)

const {
    data: promoCodeData,
    isInitialLoading: promoCodeLoading,
    isError,
} = usePromoCodeFindQuery(
    computed(() => props.modelType),
    computed(() => props.modelId),
    promoCodeSearchable
)
const { mutate: promoCodeApplyMutation, isPending: isLoadingApply } =
    usePromoCodeApply()

const errorMessage = "Invalid promo code"

watch(isError, (val) => {
    if (val) {
        formErrors.value = new GFFormErrors({
            message: errorMessage,
            errors: { promoCode: [errorMessage] },
        })
    } else formErrors.value = new GFFormErrors()
})

function findPromoCode() {
    if (promoCode.value) promoCodeSearchable.value = promoCode.value
}

function removePromoCode() {
    promoCodeSearchable.value = null
}

function onApply() {
    if (!promoCodeSearchable.value) return

    promoCodeApplyMutation(
        {
            modelType: props.modelType,
            modelId: props.modelId,
            promoCode: promoCodeSearchable.value,
        },
        {
            onSuccess: () => {
                reset()
            },
        }
    )
}

function reset() {
    promoCode.value = ""
    promoCodeSearchable.value = null
}
</script>
