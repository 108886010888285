import { useQuery } from "@tanstack/vue-query"
import useAxios from "@/composables/useAxios"
import type {
    ApiPaymentMethodIntentData,
    BillingModelId,
    BillingModelType,
} from "types/api"
import { PaymentMethodIntent } from "@/models/PaymentMethodIntent"
import type { Ref } from "vue"
import { QueryKey } from "../QueryKey"
import config from "@/config"

export function usePaymentMethodIntentQuery(
    modelType: Ref<BillingModelType>,
    modelId: Ref<BillingModelId | undefined>
) {
    return useQuery({
        queryKey: [
            QueryKey.base("user-payment-method-intent"),
            modelType,
            modelId,
        ],
        queryFn: () =>
            useAxios()
                .axios.get<ApiPaymentMethodIntentData>(
                    `/api/${modelType.value}/${modelId.value}/billing/payment-methods/intent`
                )
                .then((response) => response.data),
        select: (data) => new PaymentMethodIntent(data),
        enabled: computed(() => !!modelId.value),
        staleTime: config.staleTime.long,
    })
}
