import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import type User from "@/models/User"
import type { AxiosError } from "axios"
import type { ApiError } from "types/api"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { organizationsBaseKey } from "./useOrganizationsQuery"

export function useOrganizationUsersDelete() {
    const queryClient = useQueryClient()
    return useMutation<
        unknown,
        AxiosError<ApiError>,
        {
            organizationId: Organization["id"]
            userId: User["id"]
        }
    >({
        mutationFn: ({ organizationId, userId }) =>
            useAxios().axios.delete(
                `/api/organizations/${organizationId}/users/${userId}`
            ),
        onSettled() {
            queryClient.invalidateQueries({ queryKey: [organizationsBaseKey] })
        },
    })
}
