import { useMutation, useQueryClient } from "@tanstack/vue-query"
import type User from "@/models/User"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import type Stub from "@/models/Stub"
import { QueryKey } from "../QueryKey"

interface Payload {
    key?: string | null
    name?: string | null
    description?: string | null
}

export function useStubCreate() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (payload: {
            modelType: Stub["modelType"]
            modelId: User["id"] | Organization["id"]
            payload: Payload
        }) =>
            useAxios().axios.post(
                `/api/${payload.modelType}/${payload.modelId}/stubs`,
                payload.payload
            ),
        onSuccess() {
            queryClient.invalidateQueries({
                queryKey: QueryKey.baseKey("stubs"),
            })
        },
    })
}
