import useAxios from "@/composables/useAxios"
import { alertBaseKey } from "./useAlertQuery"
import { useMutation, useQueryClient } from "@tanstack/vue-query"

export function useAlertDeleteAll() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [alertBaseKey],
        mutationFn: () => useAxios().axios.delete(`/api/system/alerts`),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [alertBaseKey],
            })
        },
    })
}
