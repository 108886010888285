import useAxios from "@/composables/useAxios"
import type User from "@/models/User"
import UserSignature from "@/models/UserSignature"
import type { ApiCreateUserSignature, ApiUserSignatureData } from "types/api"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { QueryKey } from "../QueryKey"

export function useUserSignatureCreate() {
    const { axios } = useAxios()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            payload,
            userId,
        }: {
            payload: ApiCreateUserSignature
            userId: User["id"] | "me"
        }) =>
            axios
                .post<ApiUserSignatureData>(
                    `/api/users/${userId}/signatures`,
                    payload
                )
                .then((resp) => new UserSignature(resp.data)),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: QueryKey.baseKey("user-signatures"),
            })
        },
    })
}
