import useAxios from "@/composables/useAxios"
import { Invoice } from "@/models/Invoice"
import type {
    ApiInvoiceData,
    BillingModelId,
    BillingModelType,
} from "types/api"
import type { Ref } from "vue"
import { useQuery } from "@tanstack/vue-query"
import { QueryKey } from "../QueryKey"
import config from "@/config"

export function useInvoicesQuery(
    modelType: Ref<BillingModelType>,
    modelId: Ref<BillingModelId | undefined>
) {
    return useQuery({
        queryKey: [QueryKey.base("invoices"), modelType, modelId],

        queryFn: () =>
            useAxios()
                .axios.get<ApiInvoiceData[]>(
                    `/api/${modelType.value}/${modelId.value}/billing/invoices`
                )
                .then((response) => response.data),

        select: (data) => data.map((data) => new Invoice(data)),

        enabled: computed(() => !!modelId.value),

        staleTime: config.staleTime.long,
    })
}
