<template>
    <div class="space-y-6">
        <SectionLayout
            title="Profile"
            description="Provide some public information about yourself."
        >
            <SectionLayoutForm>
                <div class="flex flex-col space-y-6">
                    <div class="flex space-x-2">
                        <GFInput
                            v-model="firstName"
                            label="First name"
                            class="w-full"
                        />
                        <GFInput
                            v-model="lastName"
                            label="Last name"
                            class="w-full"
                        />
                    </div>

                    <div class="flex space-x-2">
                        <GFInput v-model="email" label="Email" class="w-full" />
                    </div>

                    <div>
                        <label class="block text-sm font-medium text-gray-700">
                            Avatar
                        </label>
                        <div
                            data-testid="avatar-upload"
                            class="mt-1 flex items-center"
                        >
                            <GFAvatar v-if="avatarBase64" :src="avatarBase64" />

                            <input
                                ref="avatarUpload"
                                override
                                type="file"
                                accept="image/*"
                                class="hidden"
                                @input="fileAdded"
                            />

                            <GFButtonSimple class="ml-5" @click="openUploader">
                                Change
                            </GFButtonSimple>

                            <div
                                v-if="avatarFile"
                                class="ml-4 flex flex-nowrap items-center space-x-2"
                            >
                                <div>
                                    {{ avatarFile.name }}
                                </div>

                                <GFButtonIcon
                                    data-testid="avatar-upload-clear"
                                    icon="times"
                                    class="h-8 w-8 leading-none"
                                    @click="clearAvatar"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700">
                            Verify email
                        </label>

                        <div class="mt-1 flex items-center">
                            <div
                                v-if="emailVerified"
                                class="flex items-center space-x-2"
                            >
                                <GFIcon
                                    icon="check-circle"
                                    class="text-green-500"
                                />

                                <div class="text-sm font-medium">
                                    Email verified
                                </div>
                            </div>

                            <div
                                v-if="!emailVerified"
                                class="flex flex-col space-y-1.5"
                            >
                                <div class="flex items-center space-x-2">
                                    <GFIcon
                                        icon="times-circle"
                                        class="text-red-500"
                                    />

                                    <div class="text-sm font-medium">
                                        Email not verified
                                    </div>
                                </div>

                                <GFButtonText
                                    size="sm"
                                    @click="resendVerificationEmail"
                                >
                                    <div class="flex items-center space-x-2">
                                        <div>Resend verification email</div>

                                        <GFIcon icon="envelope"></GFIcon>
                                    </div>
                                </GFButtonText>
                            </div>
                        </div>
                    </div>
                </div>

                <template #footer>
                    <div class="flex w-full flex-row-reverse">
                        <GFButton
                            :disabled="!hasChanges"
                            :loading="isLoading"
                            @click="save"
                        >
                            Save
                        </GFButton>
                    </div>
                </template>
            </SectionLayoutForm>
        </SectionLayout>
    </div>
</template>
<script lang="ts" setup>
import GFIcon from "../base/GFIcon.vue"
import GFInput from "../base/GFInput.vue"
import GFAvatar from "../base/GFAvatar.vue"
import GFButton from "../base/GFButton.vue"
import { useUserStore } from "@/stores/user"
import useAxios from "@/composables/useAxios"
import useBase64 from "@/composables/useBase64"
import type { ComponentPublicInstance } from "vue"
import GFButtonIcon from "../base/GFButtonIcon.vue"
import GFButtonText from "../base/GFButtonText.vue"
import type { ApiUpdateUserPayload } from "types/api"
import SectionLayout from "../layout/SectionLayout.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import useNotification from "@/composables/useNotification"
import { useUserUpdate } from "@/queries/users/useUserUpdate"
import SectionLayoutForm from "../layout/SectionLayoutForm.vue"

const { mutate: updateUser, isPending: isLoading } = useUserUpdate()
const notification = useNotification()

const userStore = useUserStore()
const avatarBase64 = computed(() => {
    if (!avatarFile.value)
        return userStore.user ? userStore.user?.avatar_base64 : ""

    return URL.createObjectURL(avatarFile.value)
})

const firstName = ref(userStore.user?.first_name ?? "")
const lastName = ref(userStore.user?.last_name ?? "")
const email = ref(userStore.user?.email ?? "")

const emailVerified = computed(() => userStore.user?.verifiedEmail)
const avatarFile = ref<File | null>(null)
const avatarUpload = ref<ComponentPublicInstance<HTMLInputElement> | null>(null)

const hasChanges = computed(() => {
    return (
        firstName.value !== userStore.user?.first_name ||
        lastName.value !== userStore.user?.last_name ||
        email.value !== userStore.user?.email ||
        avatarFile.value
    )
})

async function save() {
    if (userStore.user) {
        let payload: ApiUpdateUserPayload

        if (avatarFile.value) {
            const base64 = (await useBase64().toBase64(
                avatarFile.value
            )) as string

            payload = {
                first_name: firstName.value,
                last_name: lastName.value,
                email: email.value,
                avatar: base64,
            }
        } else {
            payload = {
                first_name: firstName.value,
                last_name: lastName.value,
                email: email.value,
            }
        }

        updateUser(payload, {
            onSuccess: () => {
                notification.open({
                    title: "Success",
                    message: "Account updated successfully",
                    type: "success",
                })

                if (userStore.user)
                    userStore.user.avatar_base64 = avatarBase64.value

                clearAvatar()
            },
        })
    }
}

function openUploader() {
    avatarUpload.value?.click()
}

function fileAdded(event: Event) {
    const files = (event.target as HTMLInputElement).files
    if (files) {
        avatarFile.value = files[0]
    }
}

function resendVerificationEmail() {
    useAxios()
        .axios.post("/api/email/verification/send")
        .then(() => {
            notification.open({
                title: "Success",
                message: "Verification email sent",
                type: "success",
            })
        })
}

function clearAvatar() {
    avatarFile.value = null
    if (avatarUpload.value) avatarUpload.value.value = ""
}
</script>
