import type User from "@/models/User"
import useAuth from "@/composables/useAuth"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import useNotification from "@/composables/useNotification"
import { useOrganizationStore } from "@/stores/organization"
import type { ModelSubscription } from "@/models/ModelSubscription"
import { QueryKey } from "../QueryKey"

export function useAccessCodeCreate() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (payload: {
            modelType: ModelSubscription["modelType"]
            modelId: User["id"] | Organization["id"]
            accessCode: string
        }) =>
            useAxios().axios.post(
                `/api/${payload.modelType}/${payload.modelId}/billing/access-code`,
                {
                    access_code: payload.accessCode,
                }
            ),

        onSuccess: async () => {
            queryClient.invalidateQueries({
                queryKey: QueryKey.baseKey("model-subscriptions"),
            })
            queryClient.invalidateQueries({
                queryKey: QueryKey.baseKey("invoices"),
            })
            queryClient.invalidateQueries({
                queryKey: QueryKey.baseKey("access-code"),
            })

            // We need to refresh the user data to update the billable status
            await useAuth().getMe()
            await useOrganizationStore().populateOrganizationList({
                refreshCurrent: true,
            })

            useNotification().open({
                type: "success",
                title: "Access code applied",
                message: "Your access code has been applied. Thank you!",
            })
        },
    })
}
